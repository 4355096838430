import React, { Component } from 'react';
import { Pannellum } from "pannellum-react";

class PanoramaItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highRes: this.props.allHighPanorams
        }
    }

    render() {
        const {highRes} = this.state;
        const {image, id} = this.props;

        return (
            <>
                <Pannellum 
                    image={ highRes === false ? image : id }
                    id={id}
                    pitch={-3}
                    yaw={360} 
                    hfov={110}
                    mouseZoom={false}
                    autoLoad
                />
                <button
                    className="highResBtn" 
                    onClick={(e) => {
                        e.target.classList = 'd-none';
                        this.setState({ highRes: true })
                    }}>
                        Load this file in high resolution
                </button>
                 
            </>
        )
    }
}

export default PanoramaItem;