import React,{ Component } from 'react';
import { BrowserRouter as Router,Route,Switch } from "react-router-dom";
import { Provider } from 'react-redux';
// routes
import Navbar from "./layout/Navbar";
import Images from "./pages/Images";
import Images2 from "./pages/Images2";
import Tour2Pano from "./pages/PanoramsTour2";
import TourPano4 from "./pages/PanoramaTour4";
import InteriorNormalsMenu from "./pages/InteriorNormalsMenu";
import ExteriorNormalsMenu from "./pages/ExteriorNormalsMenu";
import ExteriorPanoramatourMenu from './pages/ExteriorPanoramatourMenu';
import InteriorPanoramatourMenu from './pages/InteriorPanoramatourMenu';
import NormalTour from './pages/NormalTour';
import Default from "./pages/Default";
import Login from "./pages/Login";
import Videos from "./pages/Videos";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import store from './store';
import ConfiguratorTest from './pages/ConfiguratorTest';
import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <div id="body">
              <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/:id`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                  </React.Fragment>
                )} />
                <Route exact path={`${process.env.PUBLIC_URL}/:id/interior`} component={(props) => (
                  <React.Fragment>
                    <InteriorNormalsMenu {...props} />
                  </React.Fragment>
                )} />
                  <Route exact path={`${process.env.PUBLIC_URL}/:id/exterior`} component={(props) => (
                  <React.Fragment>
                    <ExteriorNormalsMenu {...props} />
                  </React.Fragment>
                )} />
                <Route exact path={`${process.env.PUBLIC_URL}/:id/exterior_panoramatour`} component={(props) => (
                  <React.Fragment>
                    <ExteriorPanoramatourMenu {...props} />
                  </React.Fragment>
                )} />
                <Route exact path={`${process.env.PUBLIC_URL}/:id/interior_panoramatour`} component={(props) => (
                  <React.Fragment>
                    <InteriorPanoramatourMenu {...props} />
                  </React.Fragment>
                )} />
                <Route exact path={`${process.env.PUBLIC_URL}/:id/login`} component={(props) => (
                  <React.Fragment>
                    <Login {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/panorama_tour/:house`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                    <Tour2Pano {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/panoramatour/:house`} component={(props) => (
                  <React.Fragment>
                    <TourPano4 {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/images/:house`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                    <Images {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/images_superplan7/:house`} component={(props) => (
                  <React.Fragment>
                    <Images2 {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/videos/:house`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                    <Videos {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/2d_configurator/:house/:ho_id`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                    <ConfiguratorTest {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/impressum`} component={(props) => (
                  <React.Fragment>
                    <Impressum {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/privacypolicy`} component={(props) => (
                  <React.Fragment>
                    <PrivacyPolicy {...props} />
                  </React.Fragment>
                )} />
                <Route path={`${process.env.PUBLIC_URL}/:id/normal_tour/:house`} component={(props) => (
                  <React.Fragment>
                    <Navbar {...props} />
                    <NormalTour {...props} />
                  </React.Fragment>
                )} />
                <Route component={Default} />
              </Switch>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
