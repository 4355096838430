import React,{ Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProjectFiles360,getProjectHomepage } from "../actions/projectActions";
import { Pannellum } from "pannellum-react";
import { Link } from "react-router-dom";

class PanoramsTour extends Component {
    constructor (props) {
        super(props);
        this.state = {
            planset_id: this.props.match.params.id,
            house_name: this.props.match.params.house,
            files: "",
            logo: "",
            order_info: "",
            nextDisabled: false,
            prevDisabled: false,
            validation: "",
            message: "",
            exterior_panorams: "",
            interior_panorams: "",
            interior_360_panorams: "",
            exterior_360_panorams: "",
            subIdName: ""
        }
        this.openCurrent = this.openCurrent.bind(this);
        this.nextMiniSlide = this.nextMiniSlide.bind(this);
        this.prevMiniSlide = this.prevMiniSlide.bind(this);
        this.rigthControl = this.rigthControl.bind(this);
        this.leftControl = this.leftControl.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.getProjectFiles360(this.state.planset_id,this.state.house_name);
        this.props.getProjectHomepage(this.state.planset_id);
    }

    UNSAFE_componentWillReceiveProps(nextProps,nextState) {
        const {
            interior_panorams,
            exterior_panorams
        } = nextProps.files360;
        const {
            logo,
            order_info,
            interior_360_panorams,
            exterior_360_panorams
        } = nextProps.project;
        this.setState({
            exterior_panorams: exterior_panorams,
            interior_panorams: interior_panorams,
            logo: logo,
            order_info,
            interior_360_panorams,
            exterior_360_panorams
        });
        if (this.state.interior_360_panorams !== null && this.state.interior_360_panorams !== "" && this.state.interior_360_panorams !== undefined) {
            this.state.interior_360_panorams.map(interior => {
                if (interior.house === this.props.match.params.house) {
                    this.setState({ subIdName: interior.panoram_interior_img_subcateg_name });
                }
            })
        }
        if (this.state.exterior_360_panorams !== null && this.state.exterior_360_panorams !== "" && this.state.exterior_360_panorams !== undefined) {
            this.state.exterior_360_panorams.map(exterior => {
                if (exterior.house === this.props.match.params.house) {
                    this.setState({ subIdName: exterior.panoram_exterior_img_subcateg_name });
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.house !== prevProps.match.params.house) {
            this.props.getProjectFiles360(this.state.planset_id,this.props.match.params.house);
            if (this.props.match.params.id !== prevProps.match.params.id) {
                this.props.getProjectHomepage(this.state.planset_id);
            }
            this.setState({ house_name: this.props.match.params.house });
        }
    }

    rigthControl() {
        let slides = document.getElementsByClassName("openSlider");
        let numberOfSLides = slides.length;
        let liList = document.getElementById("menuPano").getElementsByClassName("bg-white");
        for (var i = 0; i < liList.length; i++) {
            liList[i].classList.remove("text-dark");
            liList[i].classList.add("text-white");
            liList[i].classList.remove("bg-white");
        }
        let open = document.getElementsByClassName(
            "col-md-6 col-12 my-3 openSlider rounded open now"
        );
        let now;
        for (var j = 0; j < open.length; j++) {
            now = open[j].dataset.position;
        }
        if (now < numberOfSLides) {
            slides[now - 1].classList.remove("now");
            slides[now].classList.add("now");
            now++;
            document.getElementById(now).classList.add("bg-white");
            document.getElementById(now).classList.remove("text-white");
            document.getElementById(now).classList.add("text-dark");
            // start next mini slide
            let liList = document.getElementById("menuPano").getElementsByClassName("list-group-item");
            let openedList = document.getElementById("menuPano").getElementsByClassName("open");
            if (openedList !== undefined) {
                let positionToGo,positionToRemove;
                positionToRemove = openedList[0].id;
                positionToGo = parseInt(positionToRemove) + 4;
                if (positionToGo >= liList.length) {
                    this.setState({ nextDisabled: true });
                    this.setState({ prevDisabled: false });
                } else {
                    this.setState({ nextDisabled: false });
                    this.setState({ prevDisabled: false });
                }
                if (positionToGo <= liList.length) {
                    document.getElementById(positionToGo).classList.remove("d-none");
                    document.getElementById(positionToGo).classList.add("open");
                    document.getElementById(positionToRemove).classList.add("d-none");
                    document.getElementById(positionToRemove).classList.remove("open");
                }
            }
            // end next mini slide 
        } else if (parseInt(now) === parseInt(numberOfSLides)) {
            // next mini slide start
            let openedList = document.getElementById("menuPano").getElementsByClassName("open");
            let miniList = document.getElementById("menuPano").getElementsByClassName("list-group-item");
            for (var m = 0; m < openedList.length; m++) {
                openedList[m].classList.remove("open");
                openedList[m].classList.add("text-white");
            }
            this.setState({ nextDisabled: false });
            this.setState({ prevDisabled: true });
            for (var n = 0; n < miniList.length; n++) {
                if (miniList[n].id > 4) {
                    miniList[n].classList.add("d-none");
                    miniList[n].classList.remove("open");
                } else {
                    miniList[n].classList.remove("d-none");
                    miniList[n].classList.add("open");
                }
            }
            document.getElementById("1").classList.remove("text-white");
            document.getElementById("1").classList.add("bg-secondary");
            document.getElementById("1").classList.add("open");
            // next mini slide end 
            slides[numberOfSLides - 1].classList.remove("now");
            slides[0].classList.add("now");
            document.getElementById(1).classList.add("bg-secondary");
            document.getElementById(1).classList.remove("text-dark");
            document.getElementById(1).classList.add("text-white");
            now = 1;
        }
    }

    leftControl() {
        let slides = document.getElementsByClassName("openSlider");
        let numberOfSLides = slides.length;
        let liList = document.getElementById("menuPano").getElementsByClassName("bg-white");
        for (var i = 0; i < liList.length; i++) {
            liList[i].classList.remove("text-dark");
            liList[i].classList.add("text-white");
            liList[i].classList.remove("bg-white");
        }
        let open = document.getElementsByClassName(
            "col-md-6 col-12 my-3 openSlider rounded open now"
        );
        let now;
        for (var m = 0; m < open.length; m++) {
            now = open[m].dataset.position;
        }
        if (now > 1) {
            slides[now - 1].classList.remove("now");
            slides[now - 2].classList.add("now");
            now--;
            document.getElementById(now).classList.add("bg-white");
            document.getElementById(now).classList.remove("text-white");
            document.getElementById(now).classList.add("text-dark");
            // prev mini slide start 
            let closedList = document.getElementById("menuPano").getElementsByClassName("d-none");
            let openedList = document.getElementById("menuPano").getElementsByClassName("open");
            let positionToGo,positionToRemove;
            if (closedList !== undefined) {
                positionToRemove = openedList[openedList.length - 1].id;
                positionToGo = parseInt(openedList[0].id) - 1;
                if (positionToGo <= 1) {
                    this.setState({ nextDisabled: false });
                    this.setState({ prevDisabled: true });
                } else {
                    this.setState({ nextDisabled: false });
                    this.setState({ prevDisabled: false });
                }
                if (positionToGo >= 1) {
                    document.getElementById(positionToGo).classList.remove("d-none");
                    document.getElementById(positionToGo).classList.add("open");
                    document.getElementById(positionToRemove).classList.add("d-none");
                    document.getElementById(positionToRemove).classList.remove("open");
                }
            }
            // prev mini slide end 
        } else if (parseInt(now) === 1) {
            // prev mini slide start
            let openedList = document.getElementById("menuPano").getElementsByClassName("open");
            let miniList = document.getElementById("menuPano").getElementsByClassName("list-group-item");
            for (var k = 0; k < openedList.length; k++) {
                openedList[k].classList.remove("open");
                openedList[k].classList.remove("text-white");
                openedList[k].classList.add("text-dark");
            }
            this.setState({ nextDisabled: true });
            this.setState({ prevDisabled: false });
            let verify = parseInt(miniList.length - 4);
            for (var j = miniList.length - 1; j >= 0; j--) {
                if (parseInt(miniList[j].id) > verify) {
                    miniList[j].classList.remove("d-none");
                    miniList[j].classList.add("open");
                } else {
                    miniList[j].classList.add("d-none");
                    miniList[j].classList.remove("open");
                }
            }
            // prev mini slide end 
            slides[0].classList.remove("now");
            slides[numberOfSLides - 1].classList.add("now");
            now = numberOfSLides;
            document.getElementById(now).classList.add("bg-secondary");
            document.getElementById(now).classList.remove("text-dark");
            document.getElementById(now).classList.add("text-white");
        }
    }

    openCurrent(id) {
        let liList = document.getElementById("menuPano").getElementsByClassName("bg-white");
        if (liList.length === 1) {
            liList[0].classList.remove("text-dark");
            liList[0].classList.add("text-white");
            liList[0].classList.remove("bg-white");
        } else {
            for (var i = 0; i < liList.length; i++) {
                liList[i].classList.remove("text-dark");
                liList[i].classList.add("text-white");
                liList[i].classList.remove("bg-white");
            }
        }
        let open = document.getElementsByClassName(
            "col-md-6 col-12 my-3 openSlider rounded open"
        );
        for (var n = 0; n < open.length; n++) {
            if (open[n].classList.contains("now")) {
                open[n].classList.remove("now");
            }
        }
        let openNow = document.getElementById(id);
        openNow.classList.add("now");
        let positionNow = openNow.dataset.position;
        if (positionNow !== 1) {
            let first = document.getElementById("menuPano").getElementsByClassName("list-group-item");
            first[0].classList.remove("bg-white");
            first[0].classList.remove("text-dark");
            first[0].classList.add("bg-secondary");
            first[0].classList.add("text-white");
        }
        let liOpen = document.getElementById(positionNow);
        liOpen.classList.remove("text-white");
        liOpen.classList.add("text-dark");
        liOpen.classList.add("bg-white");
    }

    nextMiniSlide() {
        let liList = document.getElementById("menuPano").getElementsByClassName("list-group-item");
        let openedList = document.getElementById("menuPano").getElementsByClassName("open");
        if (openedList !== undefined) {
            let positionToGo,positionToRemove;
            positionToRemove = openedList[0].id;
            positionToGo = parseInt(positionToRemove) + 4;
            if (positionToGo <= liList.length) {
                document.getElementById(positionToGo).classList.remove("d-none");
                document.getElementById(positionToGo).classList.add("open");
                document.getElementById(positionToRemove).classList.add("d-none");
                document.getElementById(positionToRemove).classList.remove("open");
            } else if (positionToGo > liList.length) {
                let openLength = openedList.length;
                let liLength = liList.length;
                for (let i = 0; i < openLength; i++) {
                    document.getElementById("menuPano").getElementsByClassName("open")[0].classList.remove("open");
                }
                for (let j = 0; j < liLength; j++) {
                    if (j < 4) {
                        liList[j].classList.remove("d-none");
                        liList[j].classList.add("open");
                    } else {
                        liList[j].classList.add("d-none");
                    }
                }
            }
        }
    }

    prevMiniSlide() {
        let liList = document.getElementById("menuPano").getElementsByClassName("list-group-item");
        let closedList = document.getElementById("menuPano").getElementsByClassName("d-none");
        let openedList = document.getElementById("menuPano").getElementsByClassName("open");
        let positionToGo,positionToRemove;
        if (closedList !== undefined) {
            positionToRemove = openedList[openedList.length - 1].id;
            positionToGo = parseInt(openedList[0].id) - 1;
            if (positionToGo >= 1) {
                document.getElementById(positionToGo).classList.remove("d-none");
                document.getElementById(positionToGo).classList.add("open");
                document.getElementById(positionToRemove).classList.add("d-none");
                document.getElementById(positionToRemove).classList.remove("open");
            } else if (positionToGo < 1) {
                let openLength = openedList.length;
                let liLength = liList.length;
                for (let i = 0; i < openLength; i++) {
                    document.getElementById("menuPano").getElementsByClassName("open")[0].classList.remove("open");
                }
                for (let j = 0; j < liLength; j++) {
                    if (j >= liLength - 4) {
                        liList[j].classList.remove("d-none");
                        liList[j].classList.add("open");
                    } else {
                        liList[j].classList.add("d-none");
                    }
                }
            }
        }
    }

    render() {
        let interior_items = [],exterior_items = [];
        let interior_360_panorams = this.state.interior_360_panorams,exterior_360_panorams = this.state.exterior_360_panorams;
        if (interior_360_panorams !== null && interior_360_panorams !== undefined && interior_360_panorams !== "") {
            interior_360_panorams.forEach(function (item) {
                var i = interior_items.findIndex(x => x.house === item.house);
                if (i <= -1) {
                    interior_items.push({ o_id: item.o_id,house: item.house,panorams_interior_link: item.panorams_interior_link,panoram_interior_img_subcateg: item.panoram_interior_img_subcateg,panoram_interior_img_subcateg_name: item.panoram_interior_img_subcateg_name })
                }
            })
        }
        if (exterior_360_panorams !== null && exterior_360_panorams !== undefined && exterior_360_panorams !== "") {
            exterior_360_panorams.forEach(function (item) {
                var i = exterior_items.findIndex(x => x.house === item.house);
                if (i <= -1) {
                    exterior_items.push({ o_id: item.o_id,house: item.house,panorams_exterior_link: item.panorams_exterior_link,panorams_exterior_img_subcateg: item.panorams_exterior_img_subcateg,panoram_exterior_img_subcateg_name: item.panoram_exterior_img_subcateg_name })
                }
            })
        }
        let dataPosition = 0;
        let dataPositionLi = 0;

        // from string to html
        let order_name = "Order Name";
        if(this.state.order_info !== undefined && this.state.order_info !== null) {
            order_name = this.state.order_info.order_name; 
        }
        const parser = new DOMParser();
        const decoded_order_name = parser.parseFromString(`<!doctype html><body>${order_name}`, 'text/html').body.textContent;

        return (
            this.state.house_name.includes("x") ? (
                <div className="full-screen-intro main exterior-interior" style={{ height: "100%" }} id="fullScreen">
                    <div id="projectNameTour">
                        {
                            this.state.order_info !== undefined && this.state.order_info !== null ? <h3 className="text-center">{this.state.order_info.order_name !== undefined && this.state.order_info.order_name !== null ? decoded_order_name : null}{this.state.subIdName !== "" && this.state.subIdName !== undefined ? " - " + this.state.subIdName : null}</h3> : null
                        }
                    </div>
                    <div className="logoPano pb-5 mb-5">
                        <img
                            src={this.state.logo}
                            alt="logotype"
                            className="logo"
                        ></img>
                    </div>
                    <div className="slider open pano" id="slider">
                        <div className="slider-control slide-left" style={{ top: "95%",left: interior_items !== "" && interior_items !== undefined && interior_items !== null && interior_items.length > 2 ? "205px" : "" }} onClick={this.leftControl}>
                            <i className="fa fa-minus fa-2x"></i>
                        </div>
                        <div
                            className="slider-control slide-right" style={{ top: "95%" }}
                            onClick={this.rigthControl}
                        >
                            <i className="fa fa-plus fa-2x"></i>
                        </div>
                        {
                            this.state.exterior_panorams !== undefined && this.state.exterior_panorams !== null && this.state.exterior_panorams !== "" ? (
                                <div className="row w-100 mx-0 pt-5 mt-5">
                                    {
                                        this.state.exterior_panorams !== undefined && this.state.exterior_panorams !== null && this.state.exterior_panorams !== "" ? (
                                            this.state.exterior_panorams.length >= 1 ? (
                                                this.state.exterior_panorams.map(img => (
                                                    // eslint-disable-next-line
                                                    dataPosition++,
                                                    <div
                                                        className={dataPosition === 1 ? "col-md-6 col-12 my-3 openSlider rounded open now" : "col-md-6 col-12 my-3 openSlider rounded open"}
                                                        id={img[0]}
                                                        data-position={dataPosition}
                                                        key={
                                                            img[0]
                                                        }
                                                    >
                                                        <Pannellum
                                                            width="100%"
                                                            height="100%"
                                                            image={img[0]}
                                                            pitch={-3}
                                                            yaw={360}
                                                            hfov={110}
                                                            mouseZoom={false}
                                                            autoLoad
                                                        ></Pannellum>
                                                    </div>
                                                ))
                                            ) : null
                                        ) : null
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="w-100 mx-0 mb-1 fixed-bottom" id="panoramsMenu" style={{ position: "absolute",zIndex: "1000000",maxWidth: "200px",height: "100%",left: "0px" }}>
                        {
                            this.state.exterior_panorams !== undefined && this.state.exterior_panorams !== null && this.state.exterior_panorams !== "" ? (
                                <div className={this.state.exterior_panorams.length > 4 ? "d-flex flex-row align-items-center" : "d-flex flex-row pt-5"} style={{ height: "100%" }}>
                                    {exterior_items !== null && exterior_items !== "" && exterior_items !== undefined && exterior_items.length >= 1 ? (
                                        <React.Fragment>
                                            <ul className="row panorama_tour_menu border-bottom border-white mx-0 px-0 d-flex justify-content-center bg-rgba p-2 menuTopPanorama">
                                                {
                                                    exterior_items.map(exterior => {
                                                        if (exterior.house !== this.props.match.params.house) {
                                                            return (
                                                                <li className="col-12 p-1 border border-white my-1" key={exterior.panoram_exterior_img_subcateg}>
                                                                    <Link to={`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${exterior.house}`} className="w-100" style={{ textDecoration: "none" }}>
                                                                        <p className="mb-0 d-flex justify-content-center text-white">
                                                                            {
                                                                                exterior.panoram_exterior_img_subcateg_name !== "" && exterior.panoram_exterior_img_subcateg_name !== undefined && exterior.panoram_exterior_img_subcateg_name !== null ? <b>{exterior.panoram_exterior_img_subcateg_name}</b> : exterior.house !== "" && exterior.house !== undefined && exterior.house !== null ? <b>{exterior.house}</b> : null
                                                                            }
                                                                        </p>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </React.Fragment>
                                    ) : null}
                                    <ul
                                        className="list-group list-group-vertical d-flex justify-content-center mb-2 mt-5"
                                        id="menuPano"
                                        style={{ maxWidth: "200px" }}
                                    >
                                        {
                                            this.state.exterior_panorams.length > 4 ? (
                                                <div className={this.state.prevDisabled === false ? "d-flex align-items-center justify-content-center px-2 miniControl bg-secondary" : "d-flex align-items-center px-2 miniControl justify-content-center disabled bg-secondary"} onClick={this.prevMiniSlide}>
                                                    <span><i className="fas fa-chevron-up fa-2x text-white"></i></span>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            this.state.exterior_panorams !== undefined && this.state.exterior_panorams !== null && this.state.exterior_panorams !== "" ? (
                                                this.state.exterior_panorams.length >= 1 ? (
                                                    this.state.exterior_panorams.map(img => (
                                                        // eslint-disable-next-line
                                                        dataPositionLi++,
                                                        <li className={dataPositionLi === 1 ? "p-1 list-group-item bg-white text-dark open" : dataPositionLi <= 4 ? "p-1 list-group-item text-white bg-secondary open" : "p-1 list-group-item text-white bg-secondary d-none"} dataposition={dataPositionLi} id={dataPositionLi} key={dataPositionLi} onClick={() => this.openCurrent(img[0])}>
                                                            <img
                                                                className="panoramsImg"
                                                                src={img[0]}
                                                                alt={img[0]}
                                                            ></img>
                                                            {
                                                                img[1] !== "" ? (
                                                                    <p className="mb-0">{img[1]}</p>
                                                                ) : null
                                                            }
                                                        </li>
                                                    ))
                                                ) : null
                                            ) : null
                                        }
                                        {
                                            this.state.exterior_panorams.length > 4 ? (
                                                <div className={this.state.nextDisabled === false ? "d-flex justify-content-center align-items-center px-2 miniControl bg-secondary" : "d-flex justify-content-center align-items-center px-2 miniControl disabled bg-secondary"} onClick={this.nextMiniSlide}>
                                                    <span><i className="fas fa-chevron-down fa-2x text-white"></i></span>
                                                </div>
                                            ) : null
                                        }
                                    </ul>
                                    {interior_items !== null && interior_items !== "" && interior_items !== undefined && interior_items.length >= 1 ? (
                                        <React.Fragment>
                                            <ul className="row panorama_tour_menu border-bottom border-white mx-0 px-0 d-flex justify-content-center bg-rgba p-2 menuBottomPanorama">
                                                {
                                                    interior_items.map(interior => {
                                                        if (interior.house !== this.props.match.params.house) {
                                                            return (
                                                                <li className="col-12 p-1 border border-white my-1" key={interior.panoram_interior_img_subcateg}>
                                                                    <Link to={`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${interior.house}`} className="w-100" style={{ textDecoration: "none" }}>
                                                                        <p className="mb-0 d-flex justify-content-center text-white">
                                                                            {
                                                                                interior.panoram_interior_img_subcateg_name !== "" && interior.panoram_interior_img_subcateg_name !== undefined && interior.panoram_interior_img_subcateg_name !== null ? <b>{interior.panoram_interior_img_subcateg_name}</b> : interior.house !== "" && interior.house !== undefined && interior.house !== null ? <b>{interior.house}</b> : null
                                                                            }
                                                                        </p>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            ) : (
                    <div className="full-screen-intro main exterior-interior" style={{ height: "100%" }} id="fullScreen">
                        <div id="projectNameTour">
                            {
                                this.state.order_info !== undefined && this.state.order_info !== null ? <h3 className="text-center">{this.state.order_info.order_name !== undefined && this.state.order_info.order_name !== null ? this.state.order_info.order_name : null} {this.state.subIdName !== "" && this.state.subIdName !== undefined ? " - " + this.state.subIdName : null}</h3> : null
                            }
                        </div>
                        <div className="logoPano pb-5 mb-5">
                            <Link to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}>
                                <img
                                    src={this.state.logo}
                                    alt="logotype"
                                    className="logo"
                                ></img>
                            </Link>
                        </div>
                        <div className="slider open pano" id="slider">
                            <div className="slider-control slide-left" style={{ top: "95%",left: interior_items !== "" && interior_items !== undefined && interior_items !== null && interior_items.length > 2 ? "205px" : "" }} onClick={this.leftControl}>
                                <i className="fa fa-minus fa-2x"></i>
                            </div>
                            <div
                                className="slider-control slide-right" style={{ top: "95%" }}
                                onClick={this.rigthControl}
                            >
                                <i className="fa fa-plus fa-2x"></i>
                            </div>
                            {
                                this.state.interior_panorams !== undefined && this.state.interior_panorams !== null && this.state.interior_panorams !== "" ? (
                                    <div className="row w-100 mx-0 pt-5 mt-5">
                                        {
                                            this.state.interior_panorams !== undefined && this.state.interior_panorams !== null && this.state.interior_panorams !== "" ? (
                                                this.state.interior_panorams.length >= 1 ? (
                                                    this.state.interior_panorams.map(img => (
                                                        // eslint-disable-next-line
                                                        dataPosition++,
                                                        <div
                                                            className={dataPosition === 1 ? "col-md-6 col-12 my-3 openSlider rounded open now" : "col-md-6 col-12 my-3 openSlider rounded open"}
                                                            id={img[0]}
                                                            data-position={dataPosition}
                                                            key={
                                                                img[0]
                                                            }
                                                        >
                                                            <Pannellum
                                                                width="100%"
                                                                height="100%"
                                                                image={img[0]}
                                                                pitch={-3}
                                                                yaw={360}
                                                                hfov={110}
                                                                mouseZoom={false}
                                                                autoLoad
                                                            ></Pannellum>
                                                        </div>

                                                    ))
                                                ) : null
                                            ) : null
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="w-100 mx-0 mb-1 fixed-bottom" id="panoramsMenu" style={{ position: "absolute",zIndex: "1000000",maxWidth: "200px",height: "100%",left: "0px" }}>
                            {
                                this.state.interior_panorams !== undefined && this.state.interior_panorams !== null && this.state.interior_panorams !== "" ? (
                                    <div className={this.state.interior_panorams.length > 4 ? "d-flex flex-row align-items-center" : "d-flex flex-row pt-5"} style={{ height: "100%" }}>
                                        {exterior_items !== null && exterior_items !== "" && exterior_items !== undefined && exterior_items.length >= 1 ? (
                                            <React.Fragment>
                                                <ul className="row panorama_tour_menu border-bottom border-white mx-0 px-0 d-flex justify-content-center bg-rgba p-2 menuTopPanorama">
                                                    {
                                                        exterior_items.map(exterior => {
                                                            if (exterior.house !== this.props.match.params.house) {
                                                                return (
                                                                    <li className="col-12 p-1 border border-white my-1" key={exterior.panoram_exterior_img_subcateg}>
                                                                        <Link to={`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${exterior.house}`} className="w-100" style={{ textDecoration: "none" }}>
                                                                            <p className="mb-0 d-flex justify-content-center text-white">
                                                                                {
                                                                                    exterior.panoram_exterior_img_subcateg_name !== "" && exterior.panoram_exterior_img_subcateg_name !== undefined && exterior.panoram_exterior_img_subcateg_name !== null ? <b>{exterior.panoram_exterior_img_subcateg_name}</b> : exterior.house !== "" && exterior.house !== undefined && exterior.house !== null ? <b>{exterior.house}</b> : null
                                                                                }
                                                                            </p>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </React.Fragment>
                                        ) : null}
                                        <ul
                                            className="list-group list-group-vertical d-flex justify-content-center mb-2"
                                            id="menuPano"
                                            style={{ maxWidth: "200px" }}
                                        >
                                            {
                                                this.state.interior_panorams.length > 4 ? (
                                                    <div className={this.state.prevDisabled === false ? "d-flex justify-content-center align-items-center px-2 miniControl bg-secondary" : "d-flex justify-content-center align-items-center px-2 miniControl disabled bg-secondary"} onClick={this.prevMiniSlide}>
                                                        <span><i className="fas fa-chevron-up fa-2x text-white"></i></span>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                this.state.interior_panorams !== undefined && this.state.interior_panorams !== null && this.state.interior_panorams !== "" ? (
                                                    this.state.interior_panorams.length >= 1 ? (
                                                        this.state.interior_panorams.map(img => (
                                                            // eslint-disable-next-line
                                                            dataPositionLi++,
                                                            <li className={dataPositionLi === 1 ? "p-1 list-group-item bg-white text-dark open" : dataPositionLi <= 4 ? "p-1 list-group-item text-white bg-secondary open" : "p-1 list-group-item text-white bg-secondary d-none"} dataposition={dataPositionLi} id={dataPositionLi} key={dataPositionLi} onClick={() => this.openCurrent(img[0])}>
                                                                <img
                                                                    className="panoramsImg"
                                                                    src={img[0]}
                                                                    alt={img[0]}
                                                                ></img>
                                                                {
                                                                    img[1] !== "" ? (
                                                                        <p className="mb-0">{img[1]}</p>
                                                                    ) : null
                                                                }
                                                            </li>
                                                        ))
                                                    ) : null
                                                ) : null
                                            }
                                            {
                                                this.state.interior_panorams.length > 4 ? (
                                                    <div className={this.state.nextDisabled === false ? "d-flex align-items-center justify-content-center px-2 miniControl bg-secondary" : "d-flex align-items-center justify-content-center px-2 miniControl disabled bg-secondary"} onClick={this.nextMiniSlide}>
                                                        <span><i className="fas fa-chevron-down fa-2x text-white"></i></span>
                                                    </div>
                                                ) : null
                                            }
                                        </ul>
                                        {interior_items !== null && interior_items !== "" && interior_items !== undefined && interior_items.length >= 1 ? (
                                            <React.Fragment>
                                                <ul className="row panorama_tour_menu border-bottom border-white mx-0 px-0 d-flex justify-content-center bg-rgba p-2 menuBottomPanorama">
                                                    {
                                                        interior_items.map(interior => {
                                                            if (interior.house !== this.props.match.params.house) {
                                                                return (
                                                                    <li className="col-12 p-1 border border-white my-1" key={interior.panoram_interior_img_subcateg}>
                                                                        <Link to={`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${interior.house}`} className="w-100" style={{ textDecoration: "none" }}>
                                                                            <p className="mb-0 d-flex justify-content-center text-white">
                                                                                {
                                                                                    interior.panoram_interior_img_subcateg_name !== "" && interior.panoram_interior_img_subcateg_name !== undefined && interior.panoram_interior_img_subcateg_name !== null ? <b>{interior.panoram_interior_img_subcateg_name}</b> : interior.house !== "" && interior.house !== undefined && interior.house !== null ? <b>{interior.house}</b> : null
                                                                                }
                                                                            </p>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                )

        )
    }
}

PanoramsTour.propTypes = {
    project: PropTypes.object.isRequired,
    getProjectHomepage: PropTypes.func.isRequired,
    files360: PropTypes.object.isRequired,
    getProjectFiles360: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    files360: state.files360.files360,
    project: state.project.project
});

export default connect(
    mapStateToProps,
    { getProjectFiles360,getProjectHomepage }
)(PanoramsTour);