import React,{ Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProjectFiles,getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";
import { FaTimes, FaPlus, FaMinus, FaCompress, FaExpand } from 'react-icons/fa';

class Images extends Component {
    constructor (props) {
        super(props);
        this.state = {
            planset_id: this.props.match.params.id,
            house_name: this.props.match.params.house,
            interior_img: "",
            exterior_img: "",
            logo: "",
            project_name: "",
            exterior_subcategories: "",
            interior_subcategories: "",
            width: "",
            client_colors: "",
            menu_colors: "",
            mounted: false,
            startDate: Date.now(),
            maximized: "",
        };
        this.openSlider = this.openSlider.bind(this);
        this.closeSlider = this.closeSlider.bind(this);
        this.maximizes = this.maximizes.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.getProjectFiles(this.state.planset_id,this.state.house_name);
        this.props.getProjectHomepage(this.state.planset_id);
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (this.state.house_name.includes("n")) {
            const {
                logo,
                project_name,
                interior_img,
                interior_big_img
            } = nextProps.files;
            this.setState({
                logo: logo,
                project_name: project_name,
                interior_img: interior_img,
                interior_big_img: interior_big_img
            })
        }
        if (this.state.house_name.includes("x")) {
            const {
                logo,
                project_name,
                exterior_img,
                exterior_big_img
            } = nextProps.files;
            this.setState({
                logo: logo,
                project_name: project_name,
                exterior_img: exterior_img,
                exterior_big_img: exterior_big_img
            })
        }
        let exterior_subcategories;
        let interior_subcategories;
        if (this.state.house_name.includes("x")) {
            exterior_subcategories = nextProps.project;
        } else if (this.state.house_name.includes("n")) {
            interior_subcategories = nextProps.project;
        }
        const {
            client_colors,
            menu_colors
        } = nextProps.project;
        this.setState({
            client_colors: client_colors,
            menu_colors: menu_colors,
            exterior_subcategories: exterior_subcategories,
            interior_subcategories: interior_subcategories
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.house !== prevProps.match.params.house) {
            this.setState({ startDate: Date.now() })
            this.props.getProjectFiles(this.state.planset_id,this.props.match.params.house);
            if (this.props.match.params.id !== prevProps.match.params.id) {
                this.props.getProjectHomepage(this.state.planset_id);
            }
            this.setState({ house_name: this.props.match.params.house });
        }
    }

    openSlider(id) {
        if(this.state.maximized === "") {
            document.getElementById("maximizes").style.visibility = "visible";
            let parentList = document.getElementsByClassName("imgParent");
            let listBigImg = document.getElementsByClassName("big");
            for(let j = 0; j < listBigImg.length; j++) {
                listBigImg[j].classList.remove("d-none");
            }
            for(let i = 0; i < parentList.length; i++) {
                parentList[i].firstChild.classList = "img-fluid presentationImg d-none";
                parentList[i].classList = "imgParent d-block";
                parentList[i].style.height = "90%";
                parentList[i].style.overflowY = "hidden";
                parentList[i].querySelector("img").style.width = "auto";
                parentList[i].querySelector("img").style.height = "100vh";
            }
        }
        document.getElementById("fullScreen").style.overflowY = "hidden";
        document.getElementById("fullScreen").classList.add("logo-right");
        document.getElementById(id).classList.add("open");
        document.getElementById("slider").classList.add("open");
        if (
            !document.getElementById("fullScreen").classList.contains("transformed")
        ) {
            document.getElementById("menu-button").classList.add("d-none");
        }
        let list = document.getElementsByClassName("openSlider");
        for (var j = 0; j < list.length; j++) {
            if (list[j].classList.contains("open") && list[j].classList.contains("360")) {
                list[j].classList = "openSlider slide open 360";
            } else if (list[j].classList.contains("open") && !list[j].classList.contains("360")) {
                list[j].classList = "openSlider slide open";
            } else if (!list[j].classList.contains("open") && list[j].classList.contains("360")) {
                list[j].classList = "openSlider slide 360";
            } else {
                list[j].classList = "openSlider slide";
            }
        }
        let list360 = document.getElementsByClassName("360");
        for (var i = 0; i < list360.length; i++) {
            list360[i].firstChild.style.width = window.innerWidth + "px";
            list360[i].firstChild.style.height = "100%";
        }
    }

    rigthControl() {
        let slides = document.getElementsByClassName("slide");
        let numberOfSLides = slides.length;
        let open = document.getElementsByClassName(
            "slide open"
        );
        let now;
        for (var i = 0; i < open.length; i++) {
            now = parseInt(open[i].dataset.position);
        }
        if (now < numberOfSLides) {
            slides[now - 1].classList.remove("open");
            slides[now].classList.add("open");
            now++;
        } else if (now === numberOfSLides) {
            slides[numberOfSLides - 1].classList.remove("open");
            slides[0].classList.add("open");
            now = 1;
        }
    }

    leftControl() {
        let slides = document.getElementsByClassName("slide");
        let numberOfSLides = slides.length;
        let open = document.getElementsByClassName(
            "slide open"
        );
        let now;
        for (var i = 0; i < open.length; i++) {
            now = parseInt(open[i].dataset.position);
        }
        if (now > 1) {
            slides[now - 1].classList.remove("open");
            slides[now - 2].classList.add("open");
            now--;
        } else if (now === 1) {
            slides[0].classList.remove("open");
            slides[numberOfSLides - 1].classList.add("open");
            now = numberOfSLides;
        }
    }

    closeSlider() {
        this.setState({ maximized: "" });
        document.getElementById("maximizes").style.visibility = "hidden";
        let parentList = document.getElementsByClassName("imgParent");
        let titleImg = document.getElementsByClassName("titleImg");
        if(titleImg !== undefined && titleImg !== null) {
            for(let i = 0; i < titleImg.length; i++) {
                titleImg[i].style.visibility = "visible";
            }
        }
        for(let i = 0; i < parentList.length; i++) {
            parentList[i].firstChild.classList = "img-fluid presentationImg";
            parentList[i].classList = "imgParent d-block";
            parentList[i].style.height = "auto";
            parentList[i].style.overflowY = "hidden";
            parentList[i].querySelector("img").style.width = "100%";
            parentList[i].querySelector("img").style.height = "auto";
        }
        let listBigImg = document.getElementsByClassName("big");
        for(let j = 0; j < listBigImg.length; j++) {
            listBigImg[j].classList.add("d-none");
        }
        document.getElementById("fullScreen").style.overflowY = "scroll";
        document.getElementById("fullScreen").classList.remove("logo-right");
        let colList = document.getElementsByClassName("openSlider");
        let panoList = document.getElementsByClassName("360");
        for (var i = 0; i < panoList.length; i++) {
            panoList[i].firstChild.style.width = "100%";
            panoList[i].firstChild.style.height = "400px";
        }
        let list = document.getElementsByClassName(
            "slide open"
        );
        // eslint-disable-next-line
        for (var i = 0; i < list.length; i++) {
            if (list[i].classList.contains("open")) {
                list[i].classList.remove("open");
            }
        }
        for (var j = 0; j < colList.length; j++) {
            colList[j].classList.add("col-md-6");
            colList[j].classList.add("col-12");
            colList[j].classList.add("my-3");
            colList[j].classList.add("rounded");
        }
        document.getElementById("slider").classList.remove("open");
        document.getElementById("menu-button").classList.remove("d-none");
    }

    maximizes = () => {
        if(this.state.maximized === false || this.state.maximized === "") {
            this.setState({ maximized: true });
            let parentList = document.getElementsByClassName("imgParent");
            let titleImg = document.getElementsByClassName("titleImg");
            if(titleImg !== undefined && titleImg !== null) {
                for(let i = 0; i < titleImg.length; i++) {
                    titleImg[i].style.visibility = "hidden";
                }
            }
            for(let i = 0; i < parentList.length; i++){
                if(parentList[i] !== undefined) {
                    parentList[i].classList = "imgParent d-block";
                    parentList[i].style.height = "100vh";
                    parentList[i].style.width = "100%";
                    parentList[i].style.overflowY = "scroll";
                    parentList[i].querySelector("img").style.width = "100%";
                    parentList[i].querySelector("img").style.height = "auto";
                }
            }
        } else {
            this.setState({ maximized: false });
            let parentList = document.getElementsByClassName("imgParent");
            let titleImg = document.getElementsByClassName("titleImg");
            if(titleImg !== undefined && titleImg !== null) {
                for(let i = 0; i < titleImg.length; i++) {
                    titleImg[i].style.visibility = "visible";
                }
            }
            for(let i = 0; i < parentList.length; i++) {
                if(parentList[i] !== undefined) {
                    parentList[i].classList = "imgParent d-flex";
                    parentList[i].style.height = "90%";
                    parentList[i].style.width = "100%";
                    parentList[i].style.overflowY = "hidden";
                    parentList[i].querySelector("img").style.width = "auto";
                    parentList[i].querySelector("img").style.height = "100vh";
                }
            }   
        }
    }

    render() {
        let footer_style = {
            color: `#24B04D`
        };
        let footer_border = {
            borderColor: `#24B04D`
        }
        if(this.state.client_colors !== undefined && this.state.client_colors !== null) {
            footer_style = {
                color: `${this.state.client_colors.color_2}`
            }
            footer_border = {
                borderColor: `${this.state.client_colors.color_2}`
            }
        }
        let subtitle = "";
        let exterior_subcategories = [],interior_subcategories = [];
        if (this.state.house_name.includes("x")) {
            if (this.state.exterior_subcategories !== undefined) {
                if (this.state.exterior_subcategories.exterior_subcategories !== undefined) {
                    this.state.exterior_subcategories.exterior_subcategories.map(ext_subcateg => {
                        if (ext_subcateg.house === this.state.house_name) {
                            exterior_subcategories.push(ext_subcateg.exterior_img_subcateg_name);
                        }
                    })
                    for (let i = 0; i < exterior_subcategories.length; i++) {
                        if (exterior_subcategories[i] !== "") {
                            subtitle = exterior_subcategories[i];
                        }
                    }
                }
            }
        } else if (this.state.house_name.includes("n")) {
            if (this.state.interior_subcategories !== undefined) {
                if (this.state.interior_subcategories.interior_subcategories !== undefined) {
                    this.state.interior_subcategories.interior_subcategories.map(int_subcateg => {
                        if (int_subcateg.house === this.state.house_name) {
                            interior_subcategories.push(int_subcateg.interior_img_subcateg_name);
                        }
                    })
                    for (let i = 0; i < interior_subcategories.length; i++) {
                        if (interior_subcategories[i] !== "") {
                            subtitle = interior_subcategories[i];
                        }
                    }
                }
            }
        }
        let dataPosition = 0;
        let projectNameColor = {
            color: `#000`
        },style = {
            boxShadow: `#000 10px 10px 11px -8px`
        };
        if (this.state.menu_colors !== undefined && this.state.menu_colors !== null && this.state.menu_colors.text_color !== "") {
            if (this.state.menu_colors.boxShadow !== undefined && this.state.menu_colors.boxShadow !== null && this.state.menu_colors.boxShadow !== "") {
                style = {
                    boxShadow: `${this.state.menu_colors.boxShadow} 10px 10px 11px -8px`
                }
            }
            if (this.state.menu_colors.projectTitle !== undefined && this.state.menu_colors.projectTitle !== null && this.state.menu_colors.projectTitle !== "") {
                projectNameColor = {
                    color: `${this.state.menu_colors.projectTitle}`
                }
            }
        }
        let maxLength;
        if (this.state.house_name.includes("n")) {
            if (this.state.interior_img !== undefined && this.state.interior_img !== null && this.state.interior_img !== "") {
                maxLength = this.state.interior_img.length;
            }
        } else {
            if (this.state.exterior_img !== undefined && this.state.exterior_img !== null && this.state.exterior_img !== "") {
                maxLength = this.state.exterior_img.length;
            }
        }

        let order_name = "Order Name";
        if(this.state.project_name !== undefined && this.state.project_name !== null) {
            order_name = this.state.project_name;
        }
        const parser = new DOMParser();
        const decoded_order_name = parser.parseFromString(`<!doctype html><body>${order_name}`, 'text/html').body.textContent;
        let stylesLogo;
        if(this.state.planset_id === "4069") {
          stylesLogo = {
            left: "45%" , 
            width: "750px", 
            top: "-50px"
          }
        }
        return (
            <div className="full-screen-intro main exterior-interior" id="fullScreen" style={maxLength === 1 ? { maxHeight: "100vh" } : { height: "100vh" }}>
                <button className="btn btn-secondary rounded-0 d-flex align-items-center justify-content-center" style={{ position: "absolute", top: "0px", left: "0px", zIndex: "1000000", visibility: 'hidden'}} id="maximizes" onClick={this.maximizes}>
                    {
                       this.state.maximized === false ? <FaExpand size='2rem'/> : this.state.maximized === true ? 
                        <FaCompress size='2rem' />
                        : this.state.maximized === "" ? <FaExpand size='2rem'/> 
                        : null
                    }
                </button>
                <div className="logo pb-5 mb-5">
                    <Link to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}>
                        <img
                            src={this.state.logo}
                            alt="logotype"
                            className="logo"
                            style={stylesLogo}
                        ></img>
                    </Link>
                </div>
                <h2 id="projectName" className="display-4" style={projectNameColor}>
                    {decoded_order_name}
                </h2>
                {
                    subtitle !== "" ? (
                        <h4 className="mb-0 w-100" style={{ position: "absolute",zIndex: 2,top: "125px" }}>
                            {subtitle}
                        </h4>
                    ) : null
                }
                <div className="slider" id="slider">
                    <div
                        className="open-slider"
                        id="close-slider"
                        style={{ right: "21px", paddingTop: '8px' }}
                        onClick={this.closeSlider}
                    >
                        <FaTimes size='2rem' style={{ color: '#c8ccd0' }} />
                        {/* <i className="fas fa-times fa-2x"></i> */}
                    </div>
                    <div className="slider-control slide-left" onClick={this.leftControl}>
                        <FaMinus size='2rem' style={{ color: '#c8ccd0' }} />
                        {/* <i className="fa fa-minus fa-2x"></i> */}
                    </div>
                    <div
                        className="slider-control slide-right"
                        onClick={this.rigthControl}
                    >
                        <FaPlus size='2rem' style={{ color: '#c8ccd0' }} />
                        {/* <i className="fa fa-plus fa-2x"></i> */}
                    </div>
                    {
                        this.state.house_name.includes("n") ?
                            (
                                <div className="row w-100 mx-0 pt-md-5 mt-md-5">
                                    {
                                        this.state.interior_img !== undefined && this.state.interior_img !== null && this.state.interior_img !== "" ? (
                                            this.state.interior_img.length >= 1 ? (
                                                this.state.interior_img.map((img,key) => (
                                                    // eslint-disable-next-line
                                                    dataPosition++,
                                                    <div
                                                        className="col-md-6 col-12 my-3 slide openSlider rounded"
                                                        key={key}
                                                        id={img[0]}
                                                        data-position={dataPosition}
                                                        onClick={() =>
                                                            this.openSlider(
                                                                img[0]
                                                            )
                                                        }
                                                    >
                                                        <div className="imgParent">
                                                            <img
                                                                src={
                                                                    img[0]
                                                                }
                                                                alt="Interior normal image"
                                                                className="img-fluid presentationImg"
                                                                style={style}
                                                            ></img>
                                                            <img src={ this.state.interior_big_img[dataPosition - 1][2] } alt="big" className="img-fluid presentationImg big d-none"/>
                                                            <div className="img-overlay"></div>
                                                            <div className="full-screen-btn">
                                                                <span>
                                                                    {/* <i className="fas fa-compress fa-2x"></i> */}
                                                                    <FaCompress size='2rem' />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {
                                                            img[1] !== "" ? (
                                                                <h5 className="w-100 text-center titleImg py-2 text-white">{img[1]}</h5>
                                                            ) : null
                                                        }

                                                    </div>
                                                ))
                                            ) : null
                                        ) : null
                                    }
                                </div>
                            ) : (
                                <div className="row w-100 mx-0 pt-md-5 mt-md-5">
                                    {
                                        this.state.exterior_img !== undefined && this.state.exterior_img !== null && this.state.exterior_img !== "" ? (
                                            this.state.exterior_img.length >= 1 ? (
                                                this.state.exterior_img.map((img,key) => (
                                                    // eslint-disable-next-line
                                                    dataPosition++,
                                                    <div
                                                        className="col-md-6 col-12 my-3 slide openSlider rounded"
                                                        key={key}
                                                        id={img[0]}
                                                        data-position={dataPosition}
                                                        onClick={() =>
                                                            this.openSlider(
                                                                img[0]
                                                            )
                                                        }
                                                    >
                                                        <div className="imgParent">
                                                            <img
                                                                src={
                                                                    img[0]
                                                                }
                                                                alt="Exterior normal image"
                                                                className="img-fluid presentationImg"
                                                                style={style}
                                                            ></img>
                                                            <img src={ this.state.exterior_big_img[dataPosition - 1][2] } alt="big" className="img-fluid presentationImg big d-none"/>
                                                            <div className="img-overlay"></div>
                                                            <div className="full-screen-btn">
                                                                <span>
                                                                    {/* <i className="fas fa-compress fa-2x"></i> */}
                                                                    <FaCompress size='2rem' />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {
                                                            img[1] !== "" ? (
                                                                <h5 className="w-100 text-center titleImg py-2 text-white">{img[1]}</h5>
                                                            ) : null
                                                        }
                                                    </div>
                                                ))
                                            ) : null
                                        ) : null
                                    }
                                </div>
                            )
                    }
                </div>
                <p className="w-100 mx-0 mb-0 text-center" id="footer" style={footer_border}>
                    <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/privacypolicy`} style={footer_style}>
                        <strong>Datenschutzerklärung</strong>
                    </Link>
                    <strong className="mx-3 text-center" style={footer_style}>
                        |
                    </strong>
                    <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/impressum`} style={footer_style}>
                        <strong>Impressum</strong>
                    </Link>
                </p>
            </div>
        );
    }
}

Images.propTypes = {
    project: PropTypes.object.isRequired,
    getProjectHomepage: PropTypes.func.isRequired,
    files: PropTypes.object.isRequired,
    getProjectFiles: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    files: state.files.files,
    project: state.project.project
});

export default connect(
    mapStateToProps,
    { getProjectFiles, getProjectHomepage }
)(Images);
