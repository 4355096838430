import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";

class IntroPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planset_id: this.props.idFromParent,
      first_image: "",
      client_colors: "",
      logo: "",
      loading: "",
      status: "",
      message: "",
      project_name: "",
      order_info: "",
      exterior_subcategories: null,
      interior_subcategories: null
    };
    this.openMenu = this.openMenu.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const {
      first_image,
      logo,
      loading,
      project_name,
      order_info,
      client_colors,
      message,
      exterior_subcategories,
      interior_subcategories
    } = nextProps.project;
    this.setState({
      logo: logo,
      first_image: first_image,
      loading: loading,
      message: message,
      project_name: project_name,
      order_info: order_info,
      client_colors: client_colors,
      exterior_subcategories: exterior_subcategories,
      interior_subcategories: interior_subcategories
    });
  }

  UNSAFE_componentWillMount() {
    this.props.getProjectHomepage(this.state.planset_id);
  }

  openMenu(event) {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    event.preventDefault();
    if (document.getElementById("body").classList.contains("open")) {
      document.getElementById("fullScreen").classList.remove("transformed");
      if (isIE || isFirefox || isSafari || isEdge) {
        document.getElementById("fullScreen").classList.remove("left90");
      }
      setTimeout(() => {
        document.getElementById("body").classList.remove("open");
      }, 700);
      this.props.handleToUpdate(false);
    } else {
      if (window.innerWidth > 992) {
        document.getElementById("body").classList.add("open");
        document.getElementById("fullScreen").classList.add("transformed");
        if (isIE || isFirefox || isSafari || isEdge) {
          document.getElementById("fullScreen").classList.add("left90");
        }
        this.props.handleToUpdate(true);
      } else {
        if (
          document.getElementById("main-menu").classList.contains("minimized")
        ) {
          this.props.handleToUpdate(false);
        } else {
          this.props.handleToUpdate(true);
        }
      }
    }
  }

  handleImageLoaded() {
    this.setState({ status: "loaded" });
  }

  handleImageErrored() {
    this.setState({ status: "failed to load" });
  }

  render() {
    let footer_style = {
      color: `#24B04D`
    };
    let footer_border = {
      borderColor: `#24B04D`
    }
    if(this.state.client_colors !== undefined && this.state.client_colors !== null && this.state.client_colors !== "") {
        footer_style = {
            color: `${this.state.client_colors.color_2}`
        }
        footer_border = {
            borderColor: `${this.state.client_colors.color_2}`
        }
    }
    // from string to html
    let order_name = "Order Name";
    if(this.state.order_info !== undefined && this.state.order_info !== null) {
      order_name = this.state.order_info.order_name;
    }
    const parser = new DOMParser();
    const decoded_order_name = parser.parseFromString(`<!doctype html><body>${order_name}`, 'text/html').body.textContent;

    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    let styles, relativeStyles;
    if(isIE){
      relativeStyles = {
        position: "relative"
      }
    }
    if (this.state.first_image !== null && this.state.first_image !== undefined) {
      if(this.state.exterior_subcategories) {
        if(isIE){
          styles = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "url(" + this.state.exterior_subcategories[0].exterior_opt_img_subcateg + ") center center / cover no-repeat",
            height: "100%",
          }
        } else {
          styles = {
            background: "url(" + this.state.exterior_subcategories[0].exterior_opt_img_subcateg + ") center center / cover no-repeat",
            height: "100%",
          };
        }
      } else if(this.state.interior_subcategories) {
        if(isIE){
          styles = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "url(" + this.state.interior_subcategories[0].interior_opt_img_subcateg + ") center center / cover no-repeat",
            height: "100%",
          }
        } else {
          styles = {
            background: "url(" + this.state.interior_subcategories[0].interior_opt_img_subcateg + ") center center / cover no-repeat",
            height: "100%",
          };
        }
      } 
      // if(isIE){
      //   styles = {
      //     display: "flex",
      //     alignItems: "center",
      //     justifyContent: "center",
      //     background: "url(" + this.state.first_image + ") center center / cover no-repeat",
      //     height: "100%",
      //   }
      // } else {
      //   styles = {
      //     background: "url(" + this.state.first_image + ") center center / cover no-repeat",
      //     height: "100%",
      //   };
      // }
    } else {
      if(isIE) {
        styles = {
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          left: "-90px",
          height: "100%"
        }
      } else {
        styles = {
          background: "rgba(0,0,0,0.5)",
          height: "100%"
        }
      }
    }
    let stylesLogo;
    if(this.state.planset_id === "4069") {
      stylesLogo = {
        left: "45%" , 
        width: "750px", 
        top: "-50px"
      }
    }

    return this.state.loading === false ? (
        <div className="full-screen-intro main" id="fullScreen" style={styles}>
          <div className="logo">
            <img
              src={this.state.logo !== null && this.state.logo !== undefined ? this.state.logo : ""}
              alt="logotype"
              className="logo"
              style={stylesLogo}
            ></img>
          </div>
          <div className={ isIE ? "full-screen-text mx-0" : "full-screen-text m-auto" } style={relativeStyles}>
            <h4>{this.state.order_info.client_info} {this.state.order_info.adress}</h4>
            <h1 className="NamePlanset">{decoded_order_name}</h1>
            <h5 className="ContactPlanset">Email: {this.state.order_info.client_email}</h5>
            <button
              id="view"
              className="border-pulsation"
              onClick={this.openMenu}
            >
              <a href="!#">Details</a>
            </button>
          </div>
          <p className="w-100 mx-0 mb-0 text-center" id="footer" style={footer_border}>
              <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/privacypolicy`} style={footer_style}>
                  <strong>Datenschutzerklärung</strong>
              </Link>
              <strong className="mx-3 text-center" style={footer_style}>
                  |
              </strong>
              <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/impressum`} style={footer_style}>
                  <strong>Impressum</strong>
              </Link>
          </p>
        </div>
    ) : (
        <div className="loader">Loading...</div>
      );
  }
}

IntroPresentation.propTypes = {
  project: PropTypes.object.isRequired,
  getProjectHomepage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ 
  project: state.project.project
});

export default connect(
  mapStateToProps,
  { getProjectHomepage }
)(IntroPresentation);
