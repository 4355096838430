import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "../layout/layout.css";

class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            house_name: this.props.match.params.house,
            planset_id: this.props.match.params.id,
            client_colors: "",
            order_info: "",
            order_status: "",
            exterior_subcategories: "",
            exterior_videos: "",
            interior_videos: "",
            interior_subcategories: "",
            exterior_360_panorams: "",
            interior_360_panorams: "",
            menuOpen: true,
            exterior: true,
            interior: false,
            panoramsExterior: false,
            panoramsInterior: false,
            loading: "",
            logo: "",
            first_image: "",
            message: "",
        };
        this.openMenu = this.openMenu.bind(this);
        this.openExterior = this.openExterior.bind(this);
        this.openInterior = this.openInterior.bind(this);
        this.openPanoramsExterior = this.openPanoramsExterior.bind(this);
        this.openPanoramsInterior = this.openPanoramsInterior.bind(this);
        this.closeAll = this.closeAll.bind(this);
        this.handleToUpdate.bind(this);
        this.handleToCloseAll.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        const {
            client_colors,
            exterior_subcategories,
            interior_subcategories,
            exterior_360_panorams,
            interior_360_panorams,
            exterior_videos,
            interior_videos,
            loading,
            logo,
            first_image,
            message,
            order_info,
            order_status,
        } = nextProps.project;
        this.setState({
            client_colors: client_colors,
            exterior_subcategories: exterior_subcategories,
            interior_subcategories: interior_subcategories,
            exterior_360_panorams: exterior_360_panorams,
            interior_360_panorams: interior_360_panorams,
            loading: loading,
            exterior_videos: exterior_videos,
            interior_videos: interior_videos,
            logo: logo,
            first_image: first_image,
            message: message,
            order_info: order_info,
            order_status: order_status
        });
        let user = JSON.parse(localStorage.getItem('user'));
        if (user !== undefined && user !== null) {
            if (this.state.order_info.client_email !== "") {
                if (user.email !== this.state.order_info.client_email) {
                    localStorage.removeItem("user");
                    user = null;
                }
            }
        }
        let redirect = false;
        if (user === undefined || user === null) {
            redirect = true;
        } else {
            redirect = false;
        }
        if (this.state.order_status === false && redirect === true && this.props.match.params.id !== 'undefined' && this.props.match.params.id !== undefined) {
            let referrer = document.referrer;
            if (referrer.includes("cseven.eu")) {
            } else {
                this.props.history.push(`${this.props.match.params.id}/login`);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.house !== prevProps.match.params.house) {
            this.props.getProjectHomepage(this.state.planset_id);
            this.setState({ house_name: this.props.match.params.house });
            if (this.props.match.params.id !== prevProps.match.params.id) {
                this.setState({ planset_id: this.props.match.params.id });
            }
        }
    }

    redirect(url) {
        this.props.history.push(url);
    }

    UNSAFE_componentWillMount() {
        this.props.getProjectHomepage(this.state.planset_id);
    }

    handleToUpdate(update) {
        this.setState({ menuOpen: update });
    }

    handleToCloseAll() {
        this.closeAll();
    }

    handleResize = () => {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (window.innerWidth < 992) {
            if (document.getElementById("main-menu") !== undefined && document.getElementById("main-menu") !== null) {
                if (document.getElementById("main-menu").classList.contains("opened")) {
                    document.getElementById("main-menu").classList.remove("opened");
                    document.getElementById("main-menu").classList.add("minimized");
                    document.getElementById("body").classList.remove("open");
                }
            }
        } else {
            if (document.getElementById("main-menu") !== undefined && document.getElementById("main-menu") !== null) {
                if (
                    !document.getElementById("main-menu").classList.contains("minimized") &&
                    this.state.menuOpen === true
                ) {
                    document.getElementById("main-menu").classList.add("opened");
                    document.getElementById("body").classList.add("open");
                }
                if (
                    document.getElementById("main-menu").classList.contains("minimized")
                ) {
                    document.getElementById("main-menu").classList.remove("minimized");
                    document.getElementById("main-menu").classList.add("opened");
                    document.getElementById("body").classList.add("open");
                }
            }
        }
    };

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    openMenu(event) {
        const isFirefox = typeof InstallTrigger !== 'undefined';
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;
        event.preventDefault();
        this.setState({ menuOpen: !this.state.menuOpen });
        if (this.state.menuOpen === false) {
            if (window.innerWidth > 992) {
                if (document.getElementById("body") !== undefined && document.getElementById("body") !== null) {
                    document.getElementById("body").classList.add("open");
                }
            }
        } else {
            document.body.classList.remove("nav-active2");
            document.body.classList.remove("nav-active");
            document.body.classList.remove("nav-active4");
            document.body.classList.remove("nav-active5");
            setTimeout(() => {
                document.getElementById("body").classList.remove("open");
            }, 700);
        }
    }

    openExterior() {
        if (this.state.exterior === false) {
            document.body.classList.add("nav-active2");
            document.body.classList.remove("nav-active");
            document.body.classList.remove("nav-active4");
            document.body.classList.remove("nav-active5");
            this.setState({ interior: false });
            this.setState({ panoramsExterior: false });
            this.setState({ panoramsInterior: false });
            this.setState({ exterior: true });
            if (
                document.getElementById("main-menu").classList.contains("minimized")
            ) {
                document.getElementById("main-menu").classList.remove("minimized");
            }
        } else {
            document.body.classList.remove("nav-active2");
            this.setState({ exterior: false });
        }
    }

    openInterior() {
        if (this.state.interior === false) {
            document.body.classList.add("nav-active");
            document.body.classList.remove("nav-active2");
            document.body.classList.remove("nav-active4");
            document.body.classList.remove("nav-active5");
            this.setState({ exterior: false });
            this.setState({ panoramsExterior: false });
            this.setState({ panoramsInterior: false });
            this.setState({ interior: true });
            if (
                document.getElementById("main-menu").classList.contains("minimized")
            ) {
                document.getElementById("main-menu").classList.remove("minimized");
            }
        } else {
            document.body.classList.remove("nav-active");
            this.setState({ interior: false });
        }
    }

    openPanoramsExterior() {
        if (this.state.panoramsExterior === false) {
            document.body.classList.add("nav-active4");
            document.body.classList.remove("nav-active2");
            document.body.classList.remove("nav-active");
            document.body.classList.remove("nav-active5");
            this.setState({ interior: false });
            this.setState({ exterior: false });
            this.setState({ panoramsInterior: false });
            this.setState({ panoramsExterior: true });
            if (
                document.getElementById("main-menu").classList.contains("minimized")
            ) {
                document.getElementById("main-menu").classList.remove("minimized");
            }
        } else {
            document.body.classList.remove("nav-active4");
            this.setState({ panoramsExterior: false });
        }
    }

    openPanoramsInterior() {
        if (this.state.panoramsInterior === false) {
            document.body.classList.add("nav-active5");
            document.body.classList.remove("nav-active2");
            document.body.classList.remove("nav-active");
            document.body.classList.remove("nav-active4");
            this.setState({ interior: false });
            this.setState({ exterior: false });
            this.setState({ panoramsExterior: false });
            this.setState({ panoramsInterior: true });
            if (
                document.getElementById("main-menu").classList.contains("minimized")
            ) {
                document.getElementById("main-menu").classList.remove("minimized");
            }
        } else {
            document.body.classList.remove("nav-active5");
            this.setState({ panoramsInterior: false });
        }
    }

    closeAll() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        document.body.classList.remove("nav-active5");
        document.body.classList.remove("nav-active2");
        document.body.classList.remove("nav-active");
        document.body.classList.remove("nav-active4");
        this.setState({ interior: false });
        this.setState({ exterior: false });
        this.setState({ panoramsExterior: false });
        this.setState({ panoramsInterior: false });
        setTimeout(() => {
            if (document.getElementById("body") !== null && document.getElementById("body") !== undefined) {
                document.getElementById("body").classList.remove("open");
            }
        }, 700);
        document.getElementById("main-menu").classList.remove("opened");
        document.getElementById("main-menu").classList.remove("minimized");
        this.setState({ menuOpen: false });
        if (
            document.getElementById("slider") !== null &&
            document.getElementById("slider") !== undefined
        ) {
            if (!document.getElementById("slider").classList.contains("pano")) {
                if (document.getElementById("slider").classList.contains("open")) {
                    document.getElementById("slider").classList.remove("open");
                    let list = document.getElementsByClassName(
                        "col-md-6 col-12 my-3 slide openSlider rounded open"
                    );
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].classList.contains("open")) {
                            list[i].classList.remove("open");
                        }
                    }
                    document.getElementById("menu-button").classList.remove("d-none");
                }
            }
        }
        this.setState({ house: this.props.match.params.house });
    }

    logOut() {
        localStorage.removeItem('user');
        let user = JSON.parse(localStorage.getItem('user'));
        if (this.state.order_status === false) {
            let referrer = document.referrer;
            if (referrer.includes("cseven.eu")) {
            } else {
                this.props.history.push(`${this.props.match.params.id}/login`);
            }
        } else {
            this.props.history.push(`${this.props.match.params.id}`);
        }
    }

    render() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        let obj_interior = this.state.interior_subcategories;
        let obj_exterior = this.state.exterior_subcategories;
        let obj_interior_panorams = this.state.interior_360_panorams;
        let obj_exterior_panorams = this.state.exterior_360_panorams;
        let interior_menu = [];
        let exterior_menu = [];
        let interior_panorams_menu = [];
        let exterior_panorams_menu = [];
        let interior_items = [];
        let exterior_items = [];
        let interior_panorams_items = [];
        let exterior_panorams_items = [];
        Object.size = function (obj, menu) {
            var size = 0, key;
            if (size === 0) { };
            for (key in obj) {
                menu.push(key);
                if (obj.hasOwnProperty(key)) size++;
            }
        };
        if (obj_interior !== null && obj_interior !== undefined) {
            Object.size(obj_interior, interior_menu);
            interior_menu.map(interior =>
                interior_items.push(this.state.interior_subcategories[interior])
            );
        }
        if (obj_exterior !== null && obj_exterior !== undefined) {
            Object.size(obj_exterior, exterior_menu);
            exterior_menu.map(exterior =>
                exterior_items.push(this.state.exterior_subcategories[exterior])
            );
        }
        if (obj_interior_panorams !== null && obj_interior_panorams !== undefined) {
            Object.size(obj_interior_panorams, interior_panorams_menu);
            interior_panorams_menu.map(interior_panorams => {
                interior_panorams_items.push(
                    this.state.interior_360_panorams[interior_panorams]
                );
                return true;
            });
        }
        if (obj_exterior_panorams !== null && obj_exterior_panorams !== undefined) {
            Object.size(obj_exterior_panorams, exterior_panorams_menu);
            exterior_panorams_menu.map(exterior_panorams => {
                exterior_panorams_items.push(
                    this.state.exterior_360_panorams[exterior_panorams]
                );
                return true;
            });
        }
        let styles = {
            color: "#333",
            cursor: "pointer"
        };
        let A = styled.a`
      font-size: 25px;
      color: #000;
      cursor: pointer;
      :before {
        background: #fff;
      }
      &:hover {
        color: #000!important;
      }
      &:hover:before {
        right: 0 !important;
      }
      &:hover:active::before {
        right: 0 !important;
      }
      &:hover:focus::before {
        right: 0 !important;
      }
    `;
        if (this.state.client_colors !== undefined && this.state.client_colors !== null) {
            document.body.style.backgroundColor = this.state.client_colors.color_5;
            styles = {
                color: this.state.client_colors.color_1,
                cursor: "pointer"
            };
            A = styled.a`
      font-size: 25px;
      color: ${this.state.client_colors.color_3};
      cursor: pointer;
      :before {
        background: ${this.state.client_colors.color_2};
      }
      &:hover {
        color: ${this.state.client_colors.color_2}!important;
      }
      &:hover:before {
        right: 0 !important;
      }
      &:hover:active::before {
        right: 0 !important;
      }
      &:hover:focus::before {
        right: 0 !important;
      }
    `;
        }
        var resArrInterior_items = [];
        var resArrExterior_items = [];
        var resArrInterior_panoramas = [];
        var resArrExterior_panoramas = [];
        var handleToUpdate = this.handleToUpdate;
        document.title = this.state.order_info.order_name !== undefined && this.state.order_info.order_name !== null ? this.state.order_info.order_name : null;
        let user = JSON.parse(localStorage.getItem('user'));
        let redirect = false;
        if (user === undefined || user === null) {
            redirect = true;
        } else {
            redirect = false;
        }
        if (this.state.order_status === false && redirect === true && this.props.match.params.id !== 'undefined' && this.props.match.params.id !== undefined) {
            let referrer = document.referrer;
            if (referrer.includes("cseven.eu")) {
            } else {
                this.props.history.push(`${this.props.match.params.id}/login`);
            };
        }
        if (this.state.exterior === true) {
            document.body.classList.add("nav-active2");
            document.body.classList.remove("nav-active");
            document.body.classList.remove("nav-active4");
            document.body.classList.remove("nav-active5");
        }
        return (
            <>
                <Helmet>
                    <title>{this.state.order_info.order_name !== undefined && this.state.order_info.order_name !== null ? this.state.order_info.order_name : null}</title>
                </Helmet>
                <div
                    id={
                        this.state.house_name !== null &&
                            this.state.house_name !== undefined &&
                            this.state.house_name !== ""
                            ? null
                            : "body"
                    }
                >
                    <div className="row w-100 mx-0 p-0">
                        {
                            interior_items.forEach(function (item) {
                                var i = resArrInterior_items.findIndex(x => x.house === item.house);
                                if (i <= -1) {
                                    resArrInterior_items.push({ o_id: item.o_id, house: item.house, interior_link: item.interior_link, interior_img_subcateg: item.interior_img_subcateg, interior_img_subcateg_name: item.interior_img_subcateg_name })
                                }
                            }),
                        resArrInterior_items.length > 0 ? (
                <div className="nav" id="interior">
                            <div className="nav__content">
                                <h2>Innen</h2>
                                <ul className="nav__list">
                                    {
                                        resArrInterior_items.map(interior => (
                                            <li
                                                className="nav__list-item interior"
                                                key={interior.house}
                                            >
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${interior.house}`}
                                                    onClick={this.closeAll}
                                                >
                                                    <div className="card">
                                                        <img
                                                            className="card-img-top border-bottom"
                                                            alt="our files img"
                                                            src={interior.interior_img_subcateg}
                                                        ></img>
                                                        {
                                                            interior.interior_img_subcateg_name !== undefined && interior.interior_img_subcateg_name !== null && interior.interior_img_subcateg_name !== "" ? (
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{interior.interior_img_subcateg_name}</h5>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        ) : null
                      }
            {
                            exterior_items.forEach(function (item) {
                                var i = resArrExterior_items.findIndex(x => x.house === item.house);
                                if (i <= -1) {
                                    resArrExterior_items.push({ o_id: item.o_id, house: item.house, exterior_link: item.exterior_link, exterior_img_subcateg: item.exterior_img_subcateg, exterior_img_subcateg_name: item.exterior_img_subcateg_name })
                                }
                            }),
                        resArrExterior_items.length > 0 ? (
                <div className="nav" id="exterior">
                            <div className="nav__content">
                                <h2>Außen</h2>
                                <ul className="nav__list">
                                    {resArrExterior_items.map(exterior => (
                                        <li
                                            className="nav__list-item exterior"
                                            key={exterior.house}
                                        >
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${exterior.house}`}
                                                onClick={this.closeAll}
                                            >
                                                <div className="card">
                                                    <img
                                                        alt="our files img"
                                                        className="card-img-top border-bottom"
                                                        src={exterior.exterior_img_subcateg}
                                                    ></img>
                                                    {
                                                        exterior.exterior_img_subcateg_name !== undefined && exterior.exterior_img_subcateg_name !== null && exterior.exterior_img_subcateg_name !== "" ? (
                                                            <div className="card-body">
                                                                <h5 className="card-title">{exterior.exterior_img_subcateg_name}</h5>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        ) : null}
            {
                            exterior_panorams_items.forEach(function (item) {
                                var i = resArrExterior_panoramas.findIndex(x => x.house === item.house);
                                if (i <= -1) {
                                    resArrExterior_panoramas.push({ o_id: item.o_id, house: item.house, panorams_exterior_link: item.panorams_exterior_link, panoram_exterior_img_subcateg: item.panoram_exterior_img_subcateg, panoram_exterior_img_subcateg_name: item.panoram_exterior_img_subcateg_name })
                                }
                            }),
                        resArrExterior_panoramas.length > 0 ? (
                <div className="nav" id="panoramsExterior">
                            <div className="nav__content">
                                <h2>Außen Panorama Tour</h2>
                                <ul className="nav__list">
                                    {resArrExterior_panoramas.map(exterior_panorams => (
                                        <li
                                            className="nav__list-item panoramsExterior"
                                            key={exterior_panorams.house}
                                        >
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/panorama_tour/${exterior_panorams.house}`}
                                                onClick={this.closeAll}
                                            >
                                                <div className="card">
                                                    <img
                                                        alt="our files img"
                                                        className="card-img-top border-bottom"
                                                        src={exterior_panorams.panoram_exterior_img_subcateg}
                                                    ></img>
                                                    {
                                                        exterior_panorams.panoram_exterior_img_subcateg_name !== undefined && exterior_panorams.panoram_exterior_img_subcateg_name !== null && exterior_panorams.panoram_exterior_img_subcateg_name !== "" ? (
                                                            <div className="card-body">
                                                                <h5 className="card-title">
                                                                    {exterior_panorams.panoram_exterior_img_subcateg_name}
                                                                </h5>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        ) : null}
            {
                            interior_panorams_items.forEach(function (item) {
                                var i = resArrInterior_panoramas.findIndex(x => x.house === item.house);
                                if (i <= -1) {
                                    resArrInterior_panoramas.push({ o_id: item.o_id, house: item.house, panorams_interior_link: item.panorams_interior_link, panoram_interior_img_subcateg: item.panoram_interior_img_subcateg, panoram_interior_img_subcateg_name: item.panoram_interior_img_subcateg_name })
                                }
                            }),
                        resArrInterior_panoramas.length > 0 ? (
                <div className="nav" id="panoramsInterior">
                            <div className="nav__content">
                                <h2>Innen Panorama Tour</h2>
                                <ul className="nav__list">
                                    {resArrInterior_panoramas.map(interior_panorams => (
                                        <li
                                            className="nav__list-item panoramsInterior"
                                            key={interior_panorams.house}
                                        >
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/panorama_tour/${interior_panorams.house}`}
                                                onClick={this.closeAll}
                                            >
                                                <div className="card">
                                                    <img
                                                        alt="our files img"
                                                        className="card-img-top border-bottom"
                                                        src={interior_panorams.panoram_interior_img_subcateg}
                                                    ></img>
                                                    {
                                                        interior_panorams.panoram_interior_img_subcateg_name !== undefined && interior_panorams.panoram_interior_img_subcateg_name !== null && interior_panorams.panoram_interior_img_subcateg_name !== "" ? (
                                                            <div className="card-body">
                                                                <h5 className="card-title">
                                                                    {interior_panorams.panoram_interior_img_subcateg_name}
                                                                </h5>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        ) : null}
            <div
                            className={
                                this.state.menuOpen
                                    ? window.innerWidth > 992
                                        ? "d-flex flex-column justify-content-center pb-5 mb-5 opened"
                                        : "d-flex flex-column justify-content-center pb-5 mb-5 minimized"
                                    : "d-flex flex-column justify-content-center pb-5 mb-5"
                            }
                            id="main-menu"
                        >
                            <div className="logo2" id="logo2">
                                <Link to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`} onClick={this.closeAll}>
                                    <img
                                        src={this.state.logo}
                                        alt="logotype"
                                        className="logo"
                                    ></img>
                                </Link>
                            </div>
                            <ul id="navigation" className={isIE ? "d-block mx-auto" : null}>
                                <li className="navigation-item" id="home">
                                    {this.state.house_name !== null &&
                                        this.state.house_name !== undefined &&
                                        this.state.house_name !== "" ? (
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}
                                                className="navigation-link"
                                                style={styles}
                                                onClick={this.closeAll}
                                            >
                                                Startseite
                        <span>
                                                    <i className="fas fa-caret-left float-right pr-5"></i>
                                                </span>
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}
                                                className="navigation-link"
                                                style={styles}
                                                onClick={this.closeAll}
                                            >
                                                Startseite
                        <span>
                                                    <i className="fas fa-caret-left float-right pr-5"></i>
                                                </span>
                                            </Link>
                                        )}
                                </li>
                                {
                                    exterior_items !== null && exterior_items !== undefined && exterior_items.length > 0 || exterior_panorams_items !== null && exterior_panorams_items !== undefined && exterior_panorams_items.length > 0 || this.state.exterior_videos !== null && this.state.exterior_videos !== undefined && this.state.exterior_videos.length > 0 ? (
                                        <li className="navigation-item navigation-dropdown">
                                            <a
                                                className="navigation-link menu-icon2"
                                                style={{ ...styles , cursor: "auto" }}
                                >
                                  Außen :
                  <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                      </a>
                    </li>
                              ) : null
                            }
                {resArrExterior_items.length > 0 ? (
                                    <li className="navigation-item navigation-dropdown">
                                        <A
                                            onClick={resArrExterior_items.length > 1 ? this.openExterior : () => {
                                                resArrExterior_items.map(exterior_item => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${exterior_item.house}`),
                                                    this.closeAll()
                                                ))
                                            }}
                                            className="navigation-link menu-icon2 ml-5"
                                            style={styles}
                                        >
                                            - Normal
                    <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                                        </A>
                                    </li>
                                ) : null}
                                {resArrExterior_panoramas.length > 0 ? (
                                    <li className="navigation-item">
                                        <A
                                            onClick={resArrExterior_panoramas.length > 1 ? this.openPanoramsExterior : () => {
                                                resArrExterior_panoramas.map(exterior_panoram => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${exterior_panoram.house}`),
                                                    this.closeAll()
                                                ))
                                            }}
                                            className="navigation-link menu-icon4 ml-5"
                                            style={styles}
                                        >
                                            - Panoramatour {" "}
                                            <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                                        </A>
                                    </li>
                                ) : null}
                                {this.state.exterior_videos !== null && this.state.exterior_videos !== undefined ?
                                    this.state.exterior_videos.length > 0 ? (
                                        <li className="navigation-item">
                                            <A
                                                onClick={() => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.state.planset_id}/videos/x`),
                                                    this.closeAll()
                                                )}
                                                className="navigation-link menu-icon4 ml-5"
                                                style={styles}
                                            >
                                                - Videos {" "}
                                                <span>
                                                    <i className="fas fa-caret-left float-right pr-5"></i>
                                                </span>
                                            </A>
                                        </li>
                                    ) : null : null}
                                {
                                    interior_items !== null && interior_items !== undefined && interior_items.length > 0 || interior_panorams_items !== null && interior_panorams_items !== undefined && interior_panorams_items.length > 0 || this.state.interior_videos !== null && this.state.interior_videos !== undefined && this.state.interior_videos.length > 0 ? (
                                        <li className="navigation-item navigation-dropdown">
                                            <a
                                                className="navigation-link menu-icon2"
                                                style={{ ...styles ,cursor: "auto" }}
                                >
                                  Innen :
                  <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                      </a>
                    </li>
                              ) : null
                            }
                {resArrInterior_items.length > 0 ? (
                                    <li className="navigation-item navigation-dropdown">
                                        <A
                                            onClick={resArrInterior_items.length > 1 ? this.openInterior : () => {
                                                resArrInterior_items.map(interior_item => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${interior_item.house}`),
                                                    this.closeAll()
                                                ))
                                            }}
                                            className="navigation-link menu-icon ml-5"
                                            style={styles}
                                        >
                                            - Normal
                    <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                                        </A>
                                    </li>
                                ) : null}
                                {resArrInterior_panoramas.length > 0 ? (
                                    <li className="navigation-item">
                                        <A
                                            onClick={resArrInterior_panoramas.length > 1 ? this.openPanoramsInterior : () => {
                                                resArrInterior_panoramas.map(interior_panoram => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${interior_panoram.house}`),
                                                    this.closeAll()
                                                ))
                                            }}
                                            className="navigation-link menu-icon5 ml-5"
                                            style={styles}
                                        >
                                            - Panoramatour {" "}
                                            <span>
                                                <i className="fas fa-caret-left float-right pr-5"></i>
                                            </span>
                                        </A>
                                    </li>
                                ) : null}
                                {this.state.interior_videos !== null && this.state.interior_videos !== undefined ?
                                    this.state.interior_videos.length > 0 ? (
                                        <li className="navigation-item">
                                            <A
                                                onClick={() => (
                                                    // eslint-disable-next-line
                                                    this.props.history.push(`${process.env.PUBLIC_URL}/${this.state.planset_id}/videos/n`),
                                                    this.closeAll()
                                                )}
                                                className="navigation-link menu-icon4 ml-5"
                                                style={styles}
                                            >
                                                - Videos {" "}
                                                <span>
                                                    <i className="fas fa-caret-left float-right pr-5"></i>
                                                </span>
                                            </A>
                                        </li>
                                    ) : null : null}
                                {
                                    user !== undefined && user !== null ?
                                        <li className="navigation-item">
                                            <A onClick={() => this.logOut()} className="navigation-link ml-5" style={styles}>
                                                - Logout {" "}
                                                <span>
                                                    <i className="fas fa-caret-left float-right pr-5"></i>
                                                </span>
                                            </A>
                                        </li> : null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

AppNavbar.propTypes = {
    project: PropTypes.object.isRequired,
    getProjectHomepage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    project: state.project.project
});

export default connect(
    mapStateToProps,
    { getProjectHomepage }
)(AppNavbar);
