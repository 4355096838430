import * as React from "react";
import { SVGProps } from "react";

const SvgHousWindows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10.319 14.023"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      
    >
      <g
        
      >
        <path d="M-85.52 12.187h8.201v16.405h-8.202Z"  />
        <path
          d="M-82.081 14.04h1.323v3.704h-1.323zM-82.081 22.77h1.323v3.705h-1.323zM-82.081 18.537h1.323v3.44h-1.323zM-84.727 16.95h.265v7.144h-.265Z"
          fill="#c4c4c4"
        />
      </g>
      <g
        
      >
        <path d="M0 0h10.319v14.023H0Z"  />
        <path d="M.794 1.058h8.731v11.907H.794Z" fill="#e5e5e5" />
        <path d="M.53 6.615h9.26v.793H.53Z"  />
        <path d="M4.762.53h.794v12.964h-.794z"  />
      </g>
    </g>
  </svg>
);

export default SvgHousWindows;
