import * as React from "react";
import { SVGProps } from "react";

const SvgHouseWalls = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 35.631 25.165"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: "#e4e4e4",
        strokeWidth: 0.117593,
      }}
      d="M84.606 150.337c0-.069.053-.092.118-.052.064.04.117.096.117.124 0 .029-.053.052-.117.052-.065 0-.118-.056-.118-.124zm-.108-.767c.005-.137.033-.165.07-.071.035.084.03.186-.008.225-.04.039-.067-.03-.062-.154zm-.118-.824c.006-.137.034-.164.071-.07.034.084.03.186-.01.225-.038.039-.066-.03-.06-.155zm16.476-.725c-.385-.436-3.014-3.519-5.84-6.85-2.828-3.33-5.175-6.069-5.218-6.085-.042-.016-1.188 2.068-2.546 4.631-1.358 2.564-2.534 4.728-2.613 4.81-.08.082-.42.187-.758.234-.337.046-.687.157-.776.246-.31.31-.968.066-.968-.359 0-.246 6.097-11.388 6.396-11.689l.273-.274 9.395.662c5.167.364 9.5.684 9.63.71.367.077 9.753 9.299 9.795 9.625.045.345-.11.466-.917.717-.356.11-.726.23-.823.267a2.034 2.034 0 0 1-.353.091c-.097.014-3.21.932-6.92 2.042-3.708 1.109-6.813 2.016-6.899 2.016-.086 0-.472-.357-.858-.794zm-16.593-.098c.005-.137.033-.165.07-.07.035.084.03.186-.009.225-.039.039-.067-.03-.061-.155zm-.118-.823c.006-.137.034-.165.071-.07.034.084.03.185-.01.225-.038.039-.066-.03-.061-.155zm-.118-.823c.006-.137.034-.165.071-.071.034.085.03.186-.009.225-.039.04-.067-.03-.062-.154zm-.117-.823c.005-.137.033-.165.07-.071.035.085.03.186-.008.225-.04.04-.067-.03-.062-.154z"
      transform="translate(-82.137 -132.705)"
    />
    <path
      style={{
        
        strokeWidth: 0.117593,
      }}
      d="M93.484 154.225c-4.527-1.992-8.364-3.696-8.526-3.788-.28-.16-.313-.29-.631-2.57a344.718 344.718 0 0 0-.396-2.786c-.056-.347-.034-.382.24-.382.165 0 .37-.072.456-.16.086-.089 1.267-2.258 2.625-4.822 1.358-2.563 2.504-4.647 2.546-4.63.042.015 2.661 3.07 5.82 6.787 3.16 3.718 5.85 6.813 5.98 6.88.23.117.235.238.235 4.618 0 2.474-.026 4.492-.058 4.486a1567.08 1567.08 0 0 1-8.29-3.633zm21.235-4.303c.006-.137.034-.164.071-.07.034.084.03.186-.009.225-.039.039-.067-.03-.062-.155zm.47-2.704c.006-.137.034-.165.071-.071.034.085.03.186-.009.225-.039.04-.066-.03-.061-.154zm.118-.706c.006-.137.034-.165.071-.07.034.084.03.185-.009.225-.04.039-.067-.03-.062-.155zm.118-.705c.006-.137.033-.165.07-.071.035.085.03.186-.008.225-.04.04-.067-.03-.062-.154zm.117-.706c.006-.137.034-.165.071-.07.034.084.03.185-.009.224-.039.04-.067-.03-.062-.154z"
      className="HouseWalls_svg__frontWall"
      transform="translate(-82.137 -132.705)"
    />
    <path
      style={{
        filter: "brightness(0.7)",
        strokeWidth: 0.117593,
      }}
      d="m101.803 153.333.03-4.445 6.586-1.97c3.621-1.084 6.722-2.003 6.89-2.042l.307-.07-.473 2.812-.473 2.812-6.26 3.602c-3.444 1.98-6.346 3.634-6.45 3.674-.171.066-.185-.334-.157-4.373z"
      className="HouseWalls_svg__sideWall"
      transform="translate(-82.137 -132.705)"
    />
  </svg>
);

export default SvgHouseWalls;
