import * as React from "react";
import { SVGProps } from "react";

const SvgHousDoors = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8.202 16.404"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      
    >
      <g
        
      >
        <path d="M0 0h8.202v16.404H0Z"  />
        <path
          d="M3.44 1.852h1.322v3.704H3.44zM3.44 10.583h1.322v3.704H3.44zM3.44 6.35h1.322v3.44H3.44zM.794 4.762h.264v7.144H.794Z"
          fill="#c4c4c4"
        />
      </g>
      <g
        
      >
        <path d="M85.256-12.452h10.848V2.1H85.256Z" fill="#46715b" />
        <path d="M85.52-12.187h10.32V1.835H85.52Z"  />
        <path d="M86.314-11.13h8.996V1.043h-8.996Z" fill="#46715b" />
        <path d="M86.314-11.13h8.732V.778h-8.732Z" fill="#e5e5e5" />
        <path d="M86.05-5.573h9.26v.794h-9.26Z"  />
        <path d="M90.283-11.658h.794V1.306h-.794z"  />
      </g>
    </g>
  </svg>
);

export default SvgHousDoors;
