import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getProjectVideos,
  getProjectHomepage,
} from "../actions/projectActions";
import { Link } from "react-router-dom";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planset_id: this.props.match.params.id,
      house_name: this.props.match.params.house,
      interior_videos: "",
      exterior_videos: "",
      logo: "",
      order_info: "",
      width: "",
      client_colors: "",
      menu_colors: "",
      mounted: false,
      startDate: Date.now(),
    };
    this.openSlider = this.openSlider.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getProjectVideos(this.state.planset_id, this.state.house_name);
    this.props.getProjectHomepage(this.state.planset_id);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.house_name.includes("n")) {
      const { interior_videos } = nextProps.files;
      this.setState({
        interior_videos: interior_videos,
      });
    }
    if (this.state.house_name.includes("x")) {
      const { exterior_videos } = nextProps.files;
      this.setState({
        exterior_videos: exterior_videos,
      });
    }
    const { client_colors, menu_colors, order_info, logo } = nextProps.project;
    this.setState({
      client_colors: client_colors,
      menu_colors: menu_colors,
      order_info: order_info,
      logo: logo,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.house !== prevProps.match.params.house) {
      this.setState({ startDate: Date.now() });
      this.props.getProjectVideos(
        this.state.planset_id,
        this.props.match.params.house
      );
      if (this.props.match.params.id !== prevProps.match.params.id) {
        this.props.getProjectHomepage(this.state.planset_id);
      }
      this.setState({ house_name: this.props.match.params.house });
    }
  }

  openSlider(id) {
    document.getElementById("fullScreen").classList.add("logo-right");
    document.getElementById(id).classList.add("open");
    document.getElementById("slider").classList.add("open");
    if (
      !document.getElementById("fullScreen").classList.contains("transformed")
    ) {
      document.getElementById("menu-button").classList.add("d-none");
    }
    let list = document.getElementsByClassName("openSlider");
    for (var j = 0; j < list.length; j++) {
      if (
        list[j].classList.contains("open") &&
        list[j].classList.contains("360")
      ) {
        list[j].classList = "openSlider slide open 360";
      } else if (
        list[j].classList.contains("open") &&
        !list[j].classList.contains("360")
      ) {
        list[j].classList = "openSlider slide open";
      } else if (
        !list[j].classList.contains("open") &&
        list[j].classList.contains("360")
      ) {
        list[j].classList = "openSlider slide 360";
      } else {
        list[j].classList = "openSlider slide";
      }
    }
    let list360 = document.getElementsByClassName("360");
    for (var i = 0; i < list360.length; i++) {
      list360[i].firstChild.style.width = window.innerWidth + "px";
      list360[i].firstChild.style.height = "100%";
    }
  }

  rigthControl() {
    let slides = document.getElementsByClassName("slide");
    let numberOfSLides = slides.length;
    let open = document.getElementsByClassName("slide open");
    let now;
    for (var i = 0; i < open.length; i++) {
      now = parseInt(open[i].dataset.position);
    }
    if (now < numberOfSLides) {
      slides[now - 1].classList.remove("open");
      slides[now].classList.add("open");
      now++;
    } else if (now === numberOfSLides) {
      slides[numberOfSLides - 1].classList.remove("open");
      slides[0].classList.add("open");
      now = 1;
    }
  }

  leftControl() {
    let slides = document.getElementsByClassName("slide");
    let numberOfSLides = slides.length;
    let open = document.getElementsByClassName("slide open");
    let now;
    for (var i = 0; i < open.length; i++) {
      now = parseInt(open[i].dataset.position);
    }
    if (now > 1) {
      slides[now - 1].classList.remove("open");
      slides[now - 2].classList.add("open");
      now--;
    } else if (now === 1) {
      slides[0].classList.remove("open");
      slides[numberOfSLides - 1].classList.add("open");
      now = numberOfSLides;
    }
  }

  closeSlider() {
    document.getElementById("fullScreen").classList.remove("logo-right");
    let colList = document.getElementsByClassName("openSlider");
    let panoList = document.getElementsByClassName("360");
    for (var i = 0; i < panoList.length; i++) {
      panoList[i].firstChild.style.width = "100%";
      panoList[i].firstChild.style.height = "400px";
    }
    let list = document.getElementsByClassName("slide open");
    // eslint-disable-next-line
    for (var i = 0; i < list.length; i++) {
      if (list[i].classList.contains("open")) {
        list[i].classList.remove("open");
      }
    }
    if (colList.length > 1) {
      for (var j = 0; j < colList.length; j++) {
        colList[j].classList.add("col-md-6");
        colList[j].classList.add("col-12");
        colList[j].classList.add("my-3");
        colList[j].classList.add("rounded");
      }
    } else {
      for (var j = 0; j < colList.length; j++) {
        colList[j].classList.add("col-12");
        colList[j].classList.add("my-3");
        colList[j].classList.add("rounded");
      }
    }
    document.getElementById("slider").classList.remove("open");
    document.getElementById("menu-button").classList.remove("d-none");
  }

  render() {
    let stylesLogo;
    if (this.state.planset_id === "4069") {
      stylesLogo = {
        left: "45%",
        width: "750px",
        top: "-50px",
      };
    }
    let footer_style = {
      color: `#24B04D`,
    };
    let footer_border = {
      borderColor: `#24B04D`,
    };
    if (
      this.state.client_colors !== undefined &&
      this.state.client_colors !== null
    ) {
      footer_style = {
        color: `${this.state.client_colors.color_2}`,
      };
      footer_border = {
        borderColor: `${this.state.client_colors.color_2}`,
      };
    }
    let dataPosition = 0;
    let projectNameColor = {
      color: `#000`,
    };
    if (
      this.state.menu_colors !== undefined &&
      this.state.menu_colors !== null &&
      this.state.menu_colors.text_color !== ""
    ) {
      if (
        this.state.menu_colors.projectTitle !== undefined &&
        this.state.menu_colors.projectTitle !== null &&
        this.state.menu_colors.projectTitle !== ""
      ) {
        projectNameColor = {
          color: `${this.state.menu_colors.projectTitle}`,
        };
      }
    }
    let maxLength;
    if (this.state.house_name.includes("n")) {
      if (
        this.state.interior_img !== undefined &&
        this.state.interior_img !== null &&
        this.state.interior_img !== ""
      ) {
        maxLength = this.state.interior_img.length;
      }
    } else {
      if (
        this.state.exterior_img !== undefined &&
        this.state.exterior_img !== null &&
        this.state.exterior_img !== ""
      ) {
        maxLength = this.state.exterior_img.length;
      }
    }
    // from string to html
    let order_name = "Order Name";
    if (
      this.state.order_info !== undefined &&
      this.state.order_info !== null &&
      this.state.order_info !== ""
    ) {
      order_name = this.state.order_info.order_name;
    }
    const parser = new DOMParser();
    const decoded_order_name = parser.parseFromString(
      `<!doctype html><body>${order_name}`,
      "text/html"
    ).body.textContent;

    return (
      <div
        className="full-screen-intro main exterior-interior"
        id="fullScreen"
        style={maxLength === 1 ? { maxHeight: "100%" } : { height: "100%" }}
      >
        <div className="logo pb-5 mb-5">
          <Link to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}>
            <img
              src={this.state.logo}
              alt="logotype"
              className="logo"
              style={stylesLogo}
            ></img>
          </Link>
        </div>
        <h2 id="projectName" className="display-4" style={projectNameColor}>
          {this.state.order_info !== null && this.state.order_info !== undefined
            ? decoded_order_name
            : null}
        </h2>
        <div className="slider" id="slider">
          <div
            className="open-slider"
            id="close-slider"
            onClick={this.closeSlider}
          >
            <i className="fas fa-times fa-2x"></i>
          </div>
          <div className="slider-control slide-left" onClick={this.leftControl}>
            <i className="fa fa-minus fa-2x"></i>
          </div>
          <div
            className="slider-control slide-right"
            onClick={this.rigthControl}
          >
            <i className="fa fa-plus fa-2x"></i>
          </div>
          {this.state.house_name.includes("n") ? (
            <div className="row w-100 mx-0 pt-md-5 mt-md-5">
              {this.state.interior_videos !== undefined &&
              this.state.interior_videos !== null &&
              this.state.interior_videos !== ""
                ? this.state.interior_videos.length > 1
                  ? this.state.interior_videos.map(
                      (video) => (
                        // eslint-disable-next-line
                        dataPosition++,
                        (
                          <div
                            className="col-md-6 col-12 my-3 py-0 slide openSlider rounded d-flex justify-content-start flex-column pb-0 mb-0 align-items-center"
                            key={video[0]}
                            id={video[0]}
                            data-position={dataPosition}
                          >
                            <video controls>
                                <source src={`${video[0]}#t=0.001`} type="video/mp4"></source>
                            </video>
                            {video[1] !== "" ? (
                              <h5
                                className="text-center titleImg videoTitle py-2 text-white"
                                style={{ width: "720px", position: "relative" }}
                              >
                                {video[1]}
                              </h5>
                            ) : null}
                          </div>
                        )
                      )
                    )
                  : this.state.interior_videos.map(
                      (video) => (
                        // eslint-disable-next-line
                        dataPosition++,
                        (
                          <div
                            className="col-12 my-3 slide openSlider rounded d-flex justify-content-start flex-column pb-0 mb-0"
                            key={video[0]}
                            id={video[0]}
                            data-position={dataPosition}
                          >
                            <video controls>
                                <source src={`${video[0]}#t=0.001`} type="video/mp4"></source>
                            </video>
                            {video[1] !== "" ? (
                              <h5
                                className="w-100 text-center titleImg videoTitle py-2 text-white"
                                style={{ position: "relative" }}
                              >
                                {video[1]}
                              </h5>
                            ) : null}
                          </div>
                        )
                      )
                    )
                : null}
            </div>
          ) : (
            <div className="row w-100 mx-0 pt-md-5 mt-md-5">
              {this.state.exterior_videos !== undefined &&
              this.state.exterior_videos !== null &&
              this.state.exterior_videos !== ""
                ? this.state.exterior_videos.length > 1
                  ? this.state.exterior_videos.map(
                      (video) => (
                        // eslint-disable-next-line
                        dataPosition++,
                        (
                          <div
                            className="col-md-6 col-12 my-3 py-0 slide openSlider rounded d-flex justify-content-start flex-column pb-0 mb-0 align-items-center"
                            key={video[0]}
                            id={video[0]}
                            data-position={dataPosition}
                          >
                            <video controls>
                                <source src={`${video[0]}#t=0.001`} type="video/mp4"></source>
                            </video>
                            {video[1] !== "" ? (
                              <h5
                                className="text-center titleImg videoTitle py-2 text-white"
                                style={{ width: "720px", position: "relative" }}
                              >
                                {video[1]}
                              </h5>
                            ) : null}
                          </div>
                        )
                      )
                    )
                  : this.state.exterior_videos.map(
                      (video) => (
                        // eslint-disable-next-line
                        dataPosition++,
                        (
                          <div
                            className="col-12 my-3 slide openSlider rounded d-flex justify-content-start flex-column pb-0 mb-0"
                            key={video[0]}
                            id={video[0]}
                            data-position={dataPosition}
                          >
                            <video controls>
                                <source src={`${video[0]}#t=0.001`} type="video/mp4"></source>
                            </video>
                            {video[1] !== "" ? (
                              <h5
                                className="text-center titleImg videoTitle py-2 text-white w-100"
                                style={{ position: "relative" }}
                              >
                                {video[1]}
                              </h5>
                            ) : null}
                          </div>
                        )
                      )
                    )
                : null}
            </div>
          )}
        </div>
        <p
          className="w-100 mx-0 mb-0 text-center"
          id="footer"
          style={footer_border}
        >
          <Link
            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/privacypolicy`}
            style={footer_style}
          >
            <strong>Datenschutzerklärung</strong>
          </Link>
          <strong className="mx-3 text-center" style={footer_style}>
            |
          </strong>
          <Link
            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/impressum`}
            style={footer_style}
          >
            <strong>Impressum</strong>
          </Link>
        </p>
      </div>
    );
  }
}

Videos.propTypes = {
  project: PropTypes.object.isRequired,
  getProjectHomepage: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  getProjectVideos: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  files: state.files.files,
  project: state.project.project,
});

export default connect(mapStateToProps, {
  getProjectVideos,
  getProjectHomepage,
})(Videos);
