import * as React from "react";
import { SVGProps } from "react";

const SvgHousBlocks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20.108 15.875"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-135.233 -137.35)">
      <image
        width={20.108}
        height={15.875}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAtCAYAAAAUX8MIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA AXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7ZlNaBNBFMf/k25qtUm7IU0hxECC oFIpKnpQAoGcRPEgePAiSEEEEUG8eFGMF6+t4F3x4LHmqhdFEnIqxoNa6lc8JCa1abat3XwnzqTd bY3VxIWdZJf8YMPLfgR+vJk3bzYklUo10KNUq1Vks1lUKpVwIBC4B41Y0MMIggC32w2r1RqOxWJ3 oZGelmRYLBZ4PB7YbLZwNBqdhgZ6XlJhbGwMIyMjN6joI/wnpJfn5E5IksSOSLlcngqFQlInzxgm kwqiKLLjHJ2nLyliJ88YTpLBRB0OxxEq+oaK+trdb0hJBhN1Op2+zYz6/nWvYSUZtBDB5XK1FSVz CxlDFZ6dKBaLID/TyVKpRGtRKNl6nRycaRheknHIsY6Le+el8aFSiHZHie3XTCOpcNyxJF3xf5gK BoMR5Zyh52Qr9Trw+rsoJiTHs3g8fkk5T44+rDdWywQ8GQBr1/48X6lDE+z3lgvACecirh34Cru1 2jxPCGk29sLTM1m8ywngSa4oYHru93W8UgM+5/CAjq0INHDWmxZvTXxpdkDKBGxsBl1r626+cuLt j13q9+wakvk7xA8d6NqcPL9fVmOWxXwBmveL7eia5LBQU2O5Qj/uk8fQCVNV17/Rl9STrMyvondN cvajTY3lMpLQka5IJhYH8UnayCSrrCtF/SoroyuSL77tUeNlmWZR1tYAdAp3ycz6AJ4ntyTHhwpJ zJCO3tVohbtkLL1bjekwxe3JeegNd8nZhWE19g6twG+ToTdcJdkwzcgDzZh1ORf2LYEHnCW3hqrd WsMpfxE84CbJCo6y62DLxuXJNfCCm+ST93Y1ztMEnvSUwAsukq3LxmmfDK+9Bl5wkdy+OWbLxvVj /IYqg4ska+MUJpxlrllk9LdaZqEvaRb6kmahL2kW+pJmgcvLz6uHV+Ef3dh1jA7y/3/pFzwa5W7c 88ekAAAAAElFTkSuQmCC"
        x={135.233}
        y={137.35}
      />
      <path
        style={{
          fill: "#e6e6e6",
          fillOpacity: 1,
          strokeWidth: 0.352778,
        }}
        d="M141.054 153.225h-5.82v-10.936H145.802l.23.918.231.92-.93 2.262-.929 2.264V152.872H145.464l.009-2.028.009-2.029.667-1.411.668-1.411.029 3.616.028 3.616zm5.997-7.761h-.176v-.353h.353v.353zm.353-2.117h-.176v-.353h.352v.353zm2.999-.829h-3l1.5-.135 1.5-.135 1.499.135 1.5.135zm3.86-.23h-.727l.208-.337.209-.337.518.199.518.198v.278zm-.698-1.14-.164-.082-9.084-.1-9.084-.1v-3.516h14.664l1.917 1.94 1.916 1.94z"
      />
      <path
        style={{
          
          fillOpacity: 1,
          strokeWidth: 0.352778,
        }}
        d="M144.934 152.872h-.529v-4.22l.93-2.263.93-2.263-.231-.919-.231-.918h-10.57v-1.411l8.908.005 8.907.005.487.307.486.308-.243.393-.242.392h-5.88l-.214.558-.214.558v1.375l-.882 2.095-.882 2.095v3.902z"
      />
    </g>
  </svg>
);

export default SvgHousBlocks;
