import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjectHomepage } from "../actions/projectActions";
import { getProjectHouseConfigurator } from "../actions/projectActions";
import "./configurator.css";
import Parser from "html-react-parser";
import { Link } from "react-router-dom";
import Roof from "../assets/menu_icons/Roof.png";
import Walls from "../assets/menu_icons/Walls.png";
import SecondWalls from "../assets/menu_icons/Second_color.png";
import Pipe from "../assets/menu_icons/Pipe.png";
import Window from "../assets/menu_icons/Window.png";
import Door from "../assets/menu_icons/Door.png";
import JsColor from "../components/JsColor";
import Accordion from "react-bootstrap/Accordion";
import SvgHouseRoof from "./iconComponents/HouseRoof.tsx";
import { HouseRoof, HouseWalls } from "./iconComponents";
import SvgHousBlocks from "./iconComponents/HousBlocks.tsx";
import SvgHouseWalls from "./iconComponents/HouseWalls.tsx";
import SvgHousSides from "./iconComponents/HousSides.tsx";
import SvgHousWindows from "./iconComponents/HousWindows.tsx";
import SvgHousDoors from "./iconComponents/HousDoors.tsx";


class Configurator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planset_id: this.props.match.params.id,
      house: this.props.match.params.house,
      logo: "",
      house_orders_configurator: "",
      client_colors: "",
      configurator_2d_subcategories: "",
      order_info: "",
      menu: "",
      change: false,
      default_menu: "",
      roofColor: "",
      roofTextColor: "333333",
      gutterColor: "",
      gutterTextColor: "333333",
      wallColor: "",
      wallTextColor: "333333",
      wallsSecondColor: "",
      wallsTextColor: "333333",
      windowsColor: "",
      windowsTextColor: "333333",
      doorColor: "",
      doorTextColor: "333333",
      menu_elements: "",
    };
    this.setDefaultColor = this.setDefaultColor.bind(this);
    this.front = this.front.bind(this);
    this.back = this.back.bind(this);
    this.ChangeColor = this.ChangeColor.bind(this);
    this.ChangeDoor = this.ChangeDoor.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.ChangeSketchPicker = this.ChangeSketchPicker.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
    this.setContrast = this.setContrast.bind(this);
    this.removeActiveClass = this.removeActiveClass.bind(this);
    this.fromHexToRGB = this.fromHexToRGB.bind(this);
  }

  
  UNSAFE_componentWillMount() {
    this.props.getProjectHomepage(this.state.planset_id);
    this.props.getProjectHouseConfigurator(
      this.props.match.params.ho_id,
      this.state.house
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const {
      house_orders_configurator,
      logo,
      order_info,
      client_colors,
      configurator_2d_subcategories,
    } = nextProps.project;
    const { menu, configurator_container, default_menu, menu_elements } =
      nextProps.configurator;
    this.setState({
      house_orders_configurator,
      menu,
      configurator_container,
      logo,
      order_info,
      configurator_2d_subcategories,
      default_menu,
      menu_elements,
      client_colors,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.house !== prevProps.match.params.house) {
      this.props.getProjectHouseConfigurator(
        this.props.match.params.ho_id,
        this.props.match.params.house
      );
    }

    let wall_shape_items = document.getElementsByClassName("wall-shape");
    if (
      wall_shape_items !== undefined &&
      wall_shape_items !== null &&
      wall_shape_items !== "" &&
      wall_shape_items.length !== 0
    ) {
      document.addEventListener("DOMContentLoaded", this.setDefaultColor());
    }
  }

  setDefaultColor() {
    if (this.state.change === false) {
      if (
        this.state.default_menu !== "" &&
        this.state_default_menu !== null &&
        this.state.default_menu !== undefined
      ) {
        localStorage.setItem(
          "roof_shape",
          this.state.default_menu.roof_tiles_colors.color
        );
        localStorage.setItem(
          "gutter_shape",
          this.state.default_menu.gutters_colors.color
        );
        localStorage.setItem(
          "wall_shape",
          this.state.default_menu.walls_colors.color
        );
        localStorage.setItem(
          "walls_second_shape",
          this.state.default_menu.walls_second_colors.color
        );
        localStorage.setItem(
          "windows_shape",
          this.state.default_menu.windows_colors.color
        );
        localStorage.setItem(
          "door_color_shape",
          this.state.default_menu.doors_colors.color
        );
        localStorage.setItem(
          "door-shapes",
          this.state.default_menu.doors_shapes.id
        );
      }

      let roof_shape = localStorage.getItem("roof_shape"),
        gutter_shape = localStorage.getItem("gutter_shape"),
        wall_shape = localStorage.getItem("wall_shape"),
        walls_second_shape = localStorage.getItem("walls_second_shape"),
        windows_shape = localStorage.getItem("windows_shape"),
        door_color_shape = localStorage.getItem("door_color_shape"),
        door_shapes = localStorage.getItem("door-shapes");

      // Roof - Dachmaterial
      let roof_shape_text_color = this.fromHexToRGB(`#${roof_shape}`);
      this.setState({ roofTextColor: this.setContrast(roof_shape_text_color) });

      // Gutters - Dachrinnen
      let gutter_shape_text_color = this.fromHexToRGB(`#${gutter_shape}`);
      this.setState({
        gutterTextColor: this.setContrast(gutter_shape_text_color),
      });

      // Walls 1 - Fassadenfarbe 1
      let wall_shape_text_color = this.fromHexToRGB(`#${wall_shape}`);
      this.setState({ wallTextColor: this.setContrast(wall_shape_text_color) });

      // Walls 2 - Fassandenfarbe 2
      let walls_second_shape_text_color = this.fromHexToRGB(
        `#${walls_second_shape}`
      );
      this.setState({
        wallsTextColor: this.setContrast(walls_second_shape_text_color),
      });

      // Windows - Fenster
      let windows_shape_text_color = this.fromHexToRGB(`#${windows_shape}`);
      this.setState({
        windowsTextColor: this.setContrast(windows_shape_text_color),
      });

      // Doors - Türen
      let door_color_shape_text_color = this.fromHexToRGB(
        `#${door_color_shape}`
      );
      this.setState({
        doorTextColor: this.setContrast(door_color_shape_text_color),
      });

      this.setState({ roofColor: roof_shape });
      this.setState({ gutterColor: gutter_shape });
      this.setState({ wallColor: wall_shape });
      this.setState({ wallsSecondColor: walls_second_shape });
      this.setState({ windowsColor: windows_shape });
      this.setState({ doorColor: door_color_shape });

      if (document.getElementById("accordion-item-1") !== undefined) {
        document.getElementById(
          "accordion-item-1"
        ).style.background = `#${roof_shape}`;
      }

      if (document.getElementById("accordion-item-2") !== undefined) {
        document.getElementById(
          "accordion-item-2"
        ).style.background = `#${gutter_shape}`;
      }

      if (document.getElementById("accordion-item-3") !== undefined) {
        document.getElementById(
          "accordion-item-3"
        ).style.background = `#${wall_shape}`;
      }

      if (document.getElementById("accordion-item-4") !== undefined) {
        document.getElementById(
          "accordion-item-4"
        ).style.background = `#${walls_second_shape}`;
      }

      if (document.getElementById("accordion-item-5") !== undefined) {
        document.getElementById(
          "accordion-item-5"
        ).style.background = `#${windows_shape}`;
      }

      if (document.getElementById("accordion-item-6") !== undefined) {
        document.getElementById(
          "accordion-item-6"
        ).style.background = `#${door_color_shape}`;
      }

      let roof_shape_items = document.getElementsByClassName("roof-shape");
      for (let i = 0; i < roof_shape_items.length; i++) {
        roof_shape_items[i].style.fillOpacity = 1;
        roof_shape_items[i].style.fillStroke = 1;
        roof_shape_items[i].style.fill = `#${roof_shape}`;
        roof_shape_items[i].style.stroke = `#${roof_shape}`;
      }

      let gutter_shape_items = document.getElementsByClassName("gutters-shape");
      for (let i = 0; i < gutter_shape_items.length; i++) {
        gutter_shape_items[i].style.fillOpacity = 1;
        gutter_shape_items[i].style.fillStroke = 1;
        gutter_shape_items[i].style.fill = `#${gutter_shape}`;
        gutter_shape_items[i].style.stroke = `#${gutter_shape}`;
      }

      let wall_shape_items = document.getElementsByClassName("wall-shape");
      for (let i = 0; i < wall_shape_items.length; i++) {
        wall_shape_items[i].style.fillOpacity = 1;
        wall_shape_items[i].style.fillStroke = 1;
        wall_shape_items[i].style.fill = `#${wall_shape}`;
        wall_shape_items[i].style.stroke = `#${wall_shape}`;
      }

      let walls_second_shape_items =
        document.getElementsByClassName("walls_second-shape");
      for (let i = 0; i < walls_second_shape_items.length; i++) {
        walls_second_shape_items[i].style.fillOpacity = 1;
        walls_second_shape_items[i].style.fillStroke = 1;
        walls_second_shape_items[i].style.fill = `#${walls_second_shape}`;
        walls_second_shape_items[i].style.stroke = `#${walls_second_shape}`;
      }

      let windows_shape_items =
        document.getElementsByClassName("windows-shape");
      for (let i = 0; i < windows_shape_items.length; i++) {
        windows_shape_items[i].style.fillOpacity = 1;
        windows_shape_items[i].style.fillStroke = 1;
        windows_shape_items[i].style.fill = `#${windows_shape}`;
        windows_shape_items[i].style.stroke = `#${windows_shape}`;
      }

      let door_color_shape_items =
        document.getElementsByClassName("door_color-shape");
      for (let i = 0; i < door_color_shape_items.length; i++) {
        door_color_shape_items[i].style.fillOpacity = 1;
        door_color_shape_items[i].style.fillStroke = 1;
        door_color_shape_items[i].style.fill = `#${door_color_shape}`;
        door_color_shape_items[i].style.stroke = `#${door_color_shape}`;
      }

      let dsp002 = document.getElementsByClassName("dsp_002"),
        dsp004 = document.getElementsByClassName("dsp_004"),
        dsp008 = document.getElementsByClassName("dsp_008"),
        dsp010 = document.getElementsByClassName("dsp_010");
      if (dsp002[0] !== undefined && dsp002[0] !== null) {
        dsp002[0].style.display = "none";
      }
      if (dsp004[0] !== undefined && dsp004[0] !== null) {
        dsp004[0].style.display = "none";
      }
      if (dsp008[0] !== undefined && dsp008[0] !== null) {
        dsp008[0].style.display = "none";
      }
      if (dsp010[0] !== undefined && dsp010[0] !== null) {
        dsp010[0].style.display = "none";
      }
      let door_shapes_items =
        document.getElementsByClassName("door_color-shape");
      for (let i = 0; i < door_shapes_items.length; i++) {
        door_shapes_items[i].style.fillOpacity = 0;
        door_shapes_items[i].style.strokeOpacity = 0;
      }

      let activeDoorShape = document.getElementsByClassName(door_shapes);
      if (activeDoorShape[0] !== undefined && activeDoorShape[0] !== null) {
        activeDoorShape[0].style.display = "block";
      }

      let activeDoor = document.getElementsByClassName(`shape-${door_shapes}`);
      if (activeDoor[0] !== undefined) {
        activeDoor[0].style.fillOpacity = 1;
        activeDoor[0].style.strokeOpacity = 1;
      }

      if (
        document.getElementById("back-side-btn") !== null &&
        document.getElementById("back-side-btn") !== undefined
      ) {
        document
          .getElementById("back-side-btn")
          .addEventListener("click", this.back);
      }

      if (
        document.getElementById("front-side-btn") !== null &&
        document.getElementById("front-side-btn") !== undefined
      ) {
        document
          .getElementById("front-side-btn")
          .addEventListener("click", this.front);
      }

      
      this.setState({ change: true });
    }
  }

  ChangeColor(classNumber, color) {
    let classChange, listItems, textColor;
    switch (classNumber) {
      case 1:
        classChange = "roof-shape";
        listItems = document.getElementsByClassName("sw-roof-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("roof_shape", color);
        this.setState({ roofColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ roofTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 2:
        classChange = "gutters-shape";
        listItems = document.getElementsByClassName("sw-gutters-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("gutter_shape", color);
        this.setState({ gutterColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ gutterTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 3:
        classChange = "wall-shape";
        listItems = document.getElementsByClassName("sw-walls-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("wall_shape", color);
        this.setState({ wallColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ wallTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 4:
        classChange = "walls_second-shape";
        listItems = document.getElementsByClassName("sw-walls_second-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("walls_second_shape", color);
        this.setState({ wallsSecondColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ wallsTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 5:
        classChange = "windows-shape";
        listItems = document.getElementsByClassName("sw-windows-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("windows_shape", color);
        this.setState({ windowsColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ windowsTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 6:
        classChange = "door_color-shape";
        listItems = document.getElementsByClassName("sw-door_color-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-color") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        localStorage.setItem("door_color_shape", color);
        this.setState({ doorColor: color });
        textColor = this.fromHexToRGB(color);
        this.setState({ doorTextColor: this.setContrast(textColor) });
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.background = `#${color}`;
        document.getElementById(
          `accordion-item-${classNumber}`
        ).style.color = `#${this.setContrast(textColor)}`;
        // document.getElementById(`accordion-item-${classNumber}`).value = `#${color}`;
        break;
      case 7:
        this.ChangeDoor(color);
        listItems = document.getElementsByClassName("sw-door_shapes-btn");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].getAttribute("data-door_position") == color) {
            listItems[i].classList.add("active");
          } else {
            listItems[i].classList.remove("active");
          }
        }
        break;
    }
    if (classNumber !== 7) {
      let classChangeItems = document.getElementsByClassName(classChange);
      for (let i = 0; i < classChangeItems.length; i++) {
        classChangeItems[i].style.fillOpacity = 1;
        classChangeItems[i].style.fillStroke = 1;
        classChangeItems[i].style.fill = `#${color}`;
        classChangeItems[i].style.stroke = `#${color}`;
      }
    }
  }

  removeActiveClass = (classToSearch) => {
    let list = (document.getElementsByClassName = classToSearch);
    if (list !== undefined && list !== null) {
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove("active");
      }
    }
  };

  ChangeDoor(id) {
    localStorage.setItem("doos-shapes", id);
    let shapeDsp002 = document.getElementsByClassName("shape-dsp_002");
    let dsp002 = document.getElementsByClassName("dsp_002");
    if (dsp002[0] !== undefined && shapeDsp002[0] !== undefined) {
      dsp002[0].style.display = "none";
      shapeDsp002[0].style.fillOpacity = 0;
      shapeDsp002[0].style.strokeOpacity = 0;
    }

    let shapeDsp004 = document.getElementsByClassName("shape-dsp_004");
    let dsp004 = document.getElementsByClassName("dsp_004");
    if (dsp004[0] !== undefined && shapeDsp004[0] !== undefined) {
      dsp004[0].style.display = "none";
      shapeDsp004[0].style.fillOpacity = 0;
      shapeDsp004[0].style.strokeOpacity = 0;
    }

    let shapeDsp008 = document.getElementsByClassName("shape-dsp_008");
    let dsp008 = document.getElementsByClassName("dsp_008");
    if (dsp008[0] !== undefined && shapeDsp008[0] !== undefined) {
      dsp008[0].style.display = "none";
      shapeDsp008[0].style.fillOpacity = 0;
      shapeDsp008[0].style.strokeOpacity = 0;
    }

    let shapeDsp010 = document.getElementsByClassName("shape-dsp_010");
    let dsp010 = document.getElementsByClassName("dsp_010");
    if (dsp010[0] !== undefined && shapeDsp010[0] !== undefined) {
      dsp010[0].style.display = "none";
      shapeDsp010[0].style.fillOpacity = 0;
      shapeDsp010[0].style.strokeOpacity = 0;
    }

    let activeDoor = document.getElementsByClassName(`shape-${id}`);
    if (activeDoor[0] !== undefined) {
      activeDoor[0].style.fillOpacity = 1;
      activeDoor[0].style.strokeOpacity = 1;
    }
    // id.substr(0,3)+"_"+id.substr(2);

    let dspNumber = document.getElementsByClassName(id);
    if (dspNumber[0] !== undefined) {
      dspNumber[0].style.display = "block";
    }
  }

  front() {
    document.getElementById("back-side-btn").classList.remove("active");
    document.getElementById("front-side-btn").classList.add("active");
    document.getElementById("back").style.display = "none";
    document.getElementById("front").style.display = "block";
  }

  back() {
    document.getElementById("front-side-btn").classList.remove("active");
    document.getElementById("back-side-btn").classList.add("active");
    document.getElementById("back").style.display = "block";
    document.getElementById("front").style.display = "none";
  }

  handleColorRoofShape = (color) => {
    console.log(color);
    if (document.getElementById("accordion-item-1") !== undefined) {
      document.getElementById("accordion-item-1").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ roofColor: `#${colorChange}` });
    this.ChangeColor(1, colorChange);
    this.setState({ roofTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-1"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  handleColorGutterShape = (color) => {
    if (document.getElementById("accordion-item-2") !== undefined) {
      document.getElementById("accordion-item-2").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ gutterColor: `#${colorChange}` });
    this.ChangeColor(2, colorChange);
    this.setState({ gutterTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-2"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  handleColorWallShape = (color) => {
    if (document.getElementById("accordion-item-3") !== undefined) {
      document.getElementById("accordion-item-3").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ wallColor: `#${colorChange}` });
    this.ChangeColor(3, colorChange);
    this.setState({ wallTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-3"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  handleColorWallsSecondShape = (color) => {
    if (document.getElementById("accordion-item-4") !== undefined) {
      document.getElementById("accordion-item-4").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ wallsSecondColor: `#${colorChange}` });
    this.ChangeColor(4, colorChange);
    this.setState({ wallsTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-4"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  handleColorWindowsShape = (color) => {
    if (document.getElementById("accordion-item-5") !== undefined) {
      document.getElementById("accordion-item-5").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ windowsColor: `#${colorChange}` });
    this.ChangeColor(5, colorChange);
    this.setState({ windowsTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-5"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  handleColorDoorShape = (color) => {
    if (document.getElementById("accordion-item-6") !== undefined) {
      document.getElementById("accordion-item-6").style.background = `${color}`;
    }
    let colorChange = color.replace("#", "");
    let rgb = this.fromHexToRGB(color);
    this.setState({ doorColor: `#${colorChange}` });
    this.ChangeColor(6, colorChange);
    this.setState({ doorTextColor: this.setContrast(rgb) });
    document.getElementById(
      "accordion-item-6"
    ).style.color = `#${this.setContrast(rgb)}`;
  };

  setContrast(rgb) {
    let brightness = Math.round(
      (parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) /
        1000
    );
    let textColor = brightness > 125 ? "333333" : "ffffff";
    return textColor;
  }

  fromHexToRGB(h) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(h);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  changeLanguage = (lang) => {
    if ((lang = "de")) {
      if (
        document.getElementById("front-side-btn") !== undefined &&
        document.getElementById("front-side-btn") !== null
      ) {
        document.getElementById("front-side-btn").innerText = "Vorne";
      }
      if (
        document.getElementById("back-side-btn") !== undefined &&
        document.getElementById("back-side-btn") !== null
      ) {
        document.getElementById("back-side-btn").innerText = "Hinten";
      }
    } else if ((lang = "en")) {
      if (
        document.getElementById("front-side-btn") !== undefined &&
        document.getElementById("front-side-btn") !== null
      ) {
        document.getElementById("front-side-btn").innerText = "Front";
      }
      if (
        document.getElementById("back-side-btn") !== undefined &&
        document.getElementById("back-side-btn") !== null
      ) {
        document.getElementById("back-side-btn").innerText = "Back";
      }
    }
  };

  ChangeSketchPicker() {
    let sketchPickers = document.getElementsByClassName("sketch-picker");
    if (sketchPickers !== undefined && sketchPickers !== null) {
      for (let i = 0; i < sketchPickers.length; i++) {
        sketchPickers[i].style.position = "relative";
      }
    }
    let flexboxFix = document.getElementsByClassName("flexbox-fix");
    if (flexboxFix !== undefined && flexboxFix !== null) {
      for (let i = 0; i < flexboxFix.length; i++) {
        if (flexboxFix[i].style.paddingTop === "4px") {
          flexboxFix[i].style.display = "none";
        }
        if (flexboxFix[i].style.flexWrap === "wrap") {
          flexboxFix[i].style.position = "absolute";
          flexboxFix[i].style.flexDirection = "column";
          flexboxFix[i].style.right = "-25px";
          flexboxFix[i].style.top = "-15px";
          flexboxFix[i].style.maxHeight = "220px";
          flexboxFix[i].style.borderTop = "0px";
        }
      }
    }
  }

  openColorPicker = (idPicker, updown) => {
    let picker = document.getElementsByClassName(idPicker);
    if (picker[0].classList.contains("d-none")) {
      picker[0].classList.remove("d-none");
      document.getElementById(`${updown}`).classList = "fas fa-caret-up";
    } else {
      picker[0].classList.add("d-none");
      document.getElementById(`${updown}`).classList = "fas fa-caret-down";
    }
  };

  render() {
    let footer_style = {
      color: `#24B04D`,
    };
    let footer_border = {
      borderColor: `#24B04D`,
    };
    if (
      this.state.client_colors !== undefined &&
      this.state.client_colors !== null
    ) {
      footer_style = {
        color: `${this.state.client_colors.color_2}`,
      };
      footer_border = {
        borderColor: `${this.state.client_colors.color_2}`,
      };
    }
    let language,
      dataPosition = 0,
      subtitle;
    if (
      this.state.order_info !== "" &&
      this.state.order_info !== undefined &&
      this.state.order_info !== null
    ) {
      if (this.state.order_info.ln == 49) {
        language = "de";
      } else if (this.state.order_info.ln == 1) {
        language = "en";
      }
    }
    this.changeLanguage(language);
    this.ChangeSketchPicker();
    if (
      this.state.configurator_2d_subcategories !== "" &&
      this.state.configurator_2d_subcategories !== undefined &&
      this.state.configurator_2d_subcategories !== null
    ) {
      this.state.configurator_2d_subcategories.map((configurator_item) => {
        if (configurator_item.house === this.props.match.params.house) {
          subtitle = configurator_item.sub_title;
        }
      });
    }
    let logoStyles;
    if (this.state.planset_id === "4069") {
      logoStyles = {
        width: "750px",
        maxHeight: "250px",
        left: "3%",
        top: "-30px",
      };
    }
    return this.state.house_orders_configurator !== null &&
      this.state.house_orders_configurator !== undefined &&
      this.state.house_orders_configurator !== "" ? (
      <div
        className="full-screen-intro main exterior-interior configurator-screen"
        id="fullScreen"
        style={{ minHeight: "100vh" }}
      >
        <div className=" pb-5 mb-5">
          <Link to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}>
            <img
              src={this.state.logo}
              alt="logotype"
              className="logo"
              style={logoStyles}
            ></img>
          </Link>
        </div>
        <div className="container-fluid py-2 use-bootstrap px-0">
          <div className="row w-100 mx-0 configurator-row">
            <h1
              className="w-100 text-center"
              style={{ position: "absolute", top: "40px", left: "0px" }}
            >
              {this.state.order_info !== undefined &&
              this.state.order_info !== null &&
              this.state.order_info !== ""
                ? this.state.order_info.order_name
                : null}{" "}
              {subtitle !== undefined && subtitle !== null && subtitle !== '' ? '- ' + subtitle : ''}
            </h1>
            <div className="col-md-12 col-xl-9 col-sm-12 col-lg-10 mx-lg-auto">
              {this.state.configurator_container !== undefined &&
              this.state.configurator_container !== null &&
              this.state.configurator_container !== ""
                ? Parser(this.state.configurator_container)
                : null}
            </div>
            <div className="col-md-12 col-xl-3 col-sm-12 col-lg-10 mx-lg-auto px-0">
              {this.state.menu !== undefined &&
              this.state.menu !== null &&
              this.state.menu !== "" ? (
                <Accordion defaultActiveKey="collapseRoof">
                  {this.state.menu.roof_tiles_colors !== undefined &&
                  this.state.menu.roof_tiles_colors !== null &&
                  this.state.menu.roof_tiles_colors !== "" ? (
                    <Accordion.Item eventKey="collapseRoof">
                      <Accordion.Header id="headingRoof">
                        
                      
  
                    <HouseRoof fill={this.state.client_colors.color_2} width={"60px"} height={"60px!important"}/>



                      </Accordion.Header>
                      <Accordion.Body id="collapseRoof">
                        <div className="row row-cols-3 row-without-top pl-0">
                          <div className="col-12 px-0 mb-4">
                            <h4>
                              {this.state.menu_elements.roof_tiles_colors !==
                                null &&
                              this.state.menu_elements.roof_tiles_colors !==
                                undefined &&
                              this.state.menu_elements.roof_tiles_colors !== ""
                                ? this.state.menu_elements.roof_tiles_colors
                                : null}
                            </h4>
                          </div>
                          {this.state.menu.roof_tiles_colors.map(
                            (item) => (
                              dataPosition++,
                              dataPosition === 1 && this.state.change === false
                                ? localStorage.setItem("roof_shape", item.color)
                                : null,
                              (
                                <div
                                  className="col mb-3 text-center pl-0"
                                  key={item.id}
                                >
                                  <button
                                    type="button"
                                    className={
                                      dataPosition === 1
                                        ? "btn swatch sw-roof-btn active"
                                        : "btn swatch sw-roof-btn"
                                    }
                                    style={{
                                      backgroundImage: `url(${item.picture})`,
                                      width: "70px",
                                      height: "70px",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                    }}
                                    data-id={item.id}
                                    data-color={item.color}
                                    data-price={item.price}
                                    data-rm_id={item.material_id}
                                    onClick={() =>
                                      this.ChangeColor(1, item.color)
                                    }
                                  ></button>
                                  <p className="text-center mt-2 mb-0">
                                    {item.price}
                                  </p>
                                </div>
                              )
                            )
                          )}
                        </div>
                        <JsColor
                          id="accordion-item-1"
                          label="Message background color"
                          onChange={this.handleColorRoofShape}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                  {this.state.menu.gutters_colors !== undefined &&
                  this.state.menu.gutters_colors !== null &&
                  this.state.menu.gutters_colors !== "" ? (
                    <Accordion.Item eventKey="collapseGutters">
                      <Accordion.Header id="headingGutters">

                      <SvgHousBlocks fill={this.state.client_colors.color_2} width={"60px"} height={"60px!important"}/>

                      </Accordion.Header>
                      <Accordion.Body id="collapseGutters">
                        <div className="accordion-body pl-0">
                          <div className="row row-cols-3 row-without-top pl-0">
                            <div className="col-12 px-0 mb-4">
                              <h4>
                                {this.state.menu_elements.gutters_colors !==
                                  null &&
                                this.state.menu_elements.gutters_colors !==
                                  undefined &&
                                this.state.menu_elements.gutters_colors !== ""
                                  ? this.state.menu_elements.gutters_colors
                                  : null}
                              </h4>
                            </div>
                            {
                              ((dataPosition = 0),
                              this.state.menu.gutters_colors.map(
                                (item) => (
                                  dataPosition++,
                                  dataPosition === 1 &&
                                  this.state.change === false
                                    ? localStorage.setItem(
                                        "gutter_shape",
                                        item.color
                                      )
                                    : null,
                                  (
                                    <div
                                      className="col mb-3 text-center pl-0"
                                      key={item.id}
                                    >
                                      <button
                                        type="button"
                                        className={
                                          dataPosition === 1
                                            ? "btn swatch sw-gutters-btn active"
                                            : "btn swatch sw-gutters-btn"
                                        }
                                        style={{
                                          backgroundImage: `url(${item.picture})`,
                                          width: "70px",
                                          height: "70px",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                        }}
                                        data-color={item.color}
                                        data-price={item.price}
                                        onClick={() =>
                                          this.ChangeColor(2, item.color)
                                        }
                                      ></button>
                                      <p className="text-center mt-2 mb-0">
                                        {item.price}
                                      </p>
                                    </div>
                                  )
                                )
                              ))
                            }
                          </div>
                          <JsColor
                            id="accordion-item-2"
                            label="Message background color"
                            onChange={this.handleColorGutterShape}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}

                  {this.state.menu.walls_colors !== undefined &&
                  this.state.menu.walls_colors !== null &&
                  this.state.menu.walls_colors !== "" ? (
                    <Accordion.Item eventKey="collapseWalls">
                      <Accordion.Header id="headingWalls">
                        
                      <HouseWalls fill={this.state.client_colors.color_2} width={"60px"} height={"60px!important"}/>

                      </Accordion.Header>
                      <Accordion.Body id="collapseWalls">
                        <div className="accordion-body pl-0">
                          <div className="row row-cols-3 row-without-top pl-0">
                            <div className="col-12 px-0 mb-4">
                              <h4>
                                {this.state.menu_elements.walls_colors !==
                                  null &&
                                this.state.menu_elements.walls_colors !==
                                  undefined &&
                                this.state.menu_elements.walls_colors !== ""
                                  ? this.state.menu_elements.walls_colors
                                  : null}
                              </h4>
                            </div>
                            {
                              ((dataPosition = 0),
                              this.state.menu.walls_colors.map(
                                (item) => (
                                  dataPosition++,
                                  dataPosition === 1 &&
                                  this.state.change === false
                                    ? localStorage.setItem(
                                        "wall_shape",
                                        item.color
                                      )
                                    : null,
                                  (
                                    <div
                                      className="col mb-3 text-center pl-0"
                                      key={item.id}
                                    >
                                      <button
                                        type="button"
                                        className={
                                          dataPosition === 1
                                            ? "btn btn-outline-secondary sw-walls-btn active"
                                            : "btn btn-outline-secondary sw-walls-btn"
                                        }
                                        style={{
                                          backgroundColor: `#${item.color}`,
                                          width: "50px",
                                          height: "50px",
                                          boxShadow:
                                            "6px 6px 2px 1px rgba(0, 0, 0, .2)",
                                          border: "none",
                                        }}
                                        data-toggle="tooltip"
                                        data-price={item.price}
                                        data-color={item.color}
                                        onClick={() =>
                                          this.ChangeColor(3, item.color)
                                        }
                                      ></button>
                                    </div>
                                  )
                                )
                              ))
                            }
                          </div>
                          <JsColor
                            id="accordion-item-3"
                            label="Message background color"
                            onChange={this.handleColorWallShape}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}

                  {this.state.menu.walls_second_colors !== undefined &&
                  this.state.menu.walls_second_colors !== null &&
                  this.state.menu.walls_second_colors !== "" ? (
                    <Accordion.Item eventKey="collapseWallsSecond">
                      <Accordion.Header id="headingWallsSecond">
                        
                        <SvgHousSides fill={this.state.client_colors.color_2} width={"60px"} height={"60px!important"}/>

                      </Accordion.Header>
                      <Accordion.Body id="collapseWallsSecond">
                        <div className="accordion-body pl-0">
                          <div className="row row-cols-3 row-without-top pl-0">
                            <div className="col-12 px-0 mb-4">
                              <h4>
                                {this.state.menu_elements
                                  .walls_second_colors !== null &&
                                this.state.menu_elements.walls_second_colors !==
                                  undefined &&
                                this.state.menu_elements.walls_second_colors !==
                                  ""
                                  ? this.state.menu_elements.walls_second_colors
                                  : null}
                              </h4>
                            </div>
                            {
                              ((dataPosition = 0),
                              this.state.menu.walls_second_colors.map(
                                (item) => (
                                  dataPosition++,
                                  dataPosition === 1 &&
                                  this.state.change === false
                                    ? localStorage.setItem(
                                        "walls_second_shape",
                                        item.color
                                      )
                                    : null,
                                  (
                                    <div
                                      className="col mb-3 text-center pl-0"
                                      key={item.id}
                                    >
                                      <button
                                        type="button"
                                        className={
                                          dataPosition === 1
                                            ? "btn btn-outline-secondary sw-walls_second-btn active"
                                            : "btn btn-outline-secondary sw-walls_second-btn"
                                        }
                                        style={{
                                          backgroundColor: `#${item.color}`,
                                          width: "50px",
                                          height: "50px",
                                          boxShadow:
                                            "6px 6px 2px 1px rgba(0, 0, 0, .2)",
                                          border: "none",
                                        }}
                                        data-toggle="tooltip"
                                        data-price={item.price}
                                        data-color={item.color}
                                        onClick={() =>
                                          this.ChangeColor(4, item.color)
                                        }
                                      ></button>
                                    </div>
                                  )
                                )
                              ))
                            }
                          </div>
                          <JsColor
                            id="accordion-item-4"
                            label="Message background color"
                            onChange={this.handleColorWallsSecondShape}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}

                  {this.state.menu.windows_colors !== undefined &&
                  this.state.menu.windows_colors !== null &&
                  this.state.menu.windows_colors !== "" ? (
                    <Accordion.Item eventKey="collapseWindows">
                      <Accordion.Header id="headingWindows">
                        
                      <SvgHousWindows fill={this.state.client_colors.color_2} width={"40px"} height={"40px!important"}/>

                      </Accordion.Header>
                      <Accordion.Body id="collapseWindows">
                        <div className="accordion-body pl-0">
                          <div className="row row-cols-3 row-without-top pl-0">
                            <div className="col-12 px-0 mb-4">
                              <h4>
                                {this.state.menu_elements.windows_colors !==
                                  null &&
                                this.state.menu_elements.windows_colors !==
                                  undefined &&
                                this.state.menu_elements.windows_colors !== ""
                                  ? this.state.menu_elements.windows_colors
                                  : null}
                              </h4>
                            </div>
                            {
                              ((dataPosition = 0),
                              this.state.menu.windows_colors.map(
                                (item) => (
                                  dataPosition++,
                                  dataPosition === 1 &&
                                  this.state.change === false
                                    ? localStorage.setItem(
                                        "windows_shape",
                                        item.color
                                      )
                                    : null,
                                  (
                                    <div
                                      className="col mb-3 text-center pl-0"
                                      key={item.id}
                                    >
                                      <button
                                        type="button"
                                        className={
                                          dataPosition === 1
                                            ? "btn btn-outline-secondary sw-windows-btn active"
                                            : "btn btn-outline-secondary sw-windows-btn"
                                        }
                                        style={{
                                          backgroundColor: `#${item.color}`,
                                          width: "50px",
                                          height: "50px",
                                          boxShadow:
                                            "6px 6px 2px 1px rgba(0, 0, 0, .2)",
                                          border: "none",
                                        }}
                                        data-toggle="tooltip"
                                        data-price={item.price}
                                        data-color={item.color}
                                        onClick={() =>
                                          this.ChangeColor(5, item.color)
                                        }
                                      ></button>
                                    </div>
                                  )
                                )
                              ))
                            }
                          </div>
                          <JsColor
                            id="accordion-item-5"
                            label="Message background color"
                            onChange={this.handleColorWindowsShape}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}

                  {this.state.menu.doors_colors === undefined ||
                  this.state.menu.doors_colors === null ||
                  this.state.menu.doors_colors === "" ||
                  this.state.menu.doors_shapes !== undefined ||
                  this.state.menu.doors_shapes !== null ||
                  this.state.menu.doors_shapes !== "" ? (
                    <Accordion.Item eventKey="collapseDoors">
                      <Accordion.Header id="headingDoors">
                        
                        <SvgHousDoors fill={this.state.client_colors.color_2} width={"30px"} height={"30px!important"}/>

                      </Accordion.Header>
                      <Accordion.Body id="collapseDoors">
                        <div className="accordion-body pl-0">
                          <div className="row row-cols-3 row-without-top pl-0">
                            <div className="col-12 px-0 mb-4">
                              <h4>
                                {this.state.menu_elements.doors !== null &&
                                this.state.menu_elements.doors !== undefined &&
                                this.state.menu_elements.doors !== ""
                                  ? this.state.menu_elements.doors
                                  : null}
                              </h4>
                            </div>
                            {
                              ((dataPosition = 0),
                              this.state.menu.doors_colors !== undefined &&
                              this.state.menu.doors_colors !== null &&
                              this.state.menu.doors_colors !== ""
                                ? this.state.menu.doors_colors.map(
                                    (item) => (
                                      dataPosition++,
                                      dataPosition === 1 &&
                                      this.state.change === false
                                        ? localStorage.setItem(
                                            "door_color_shape",
                                            item.color
                                          )
                                        : null,
                                      (
                                        <div
                                          className="col mb-3 text-center pl-0"
                                          key={item.color}
                                        >
                                          <button
                                            type="button"
                                            className={
                                              dataPosition === 1
                                                ? "btn btn-outline-secondary sw-door_color-btn active"
                                                : "btn btn-outline-secondary sw-door_color-btn"
                                            }
                                            style={{
                                              backgroundColor: `#${item.color}`,
                                              width: "50px",
                                              height: "50px",
                                              boxShadow:
                                                "6px 6px 2px 1px rgba(0, 0, 0, .2)",
                                              border: "none",
                                            }}
                                            data-toggle="tooltip"
                                            data-price={item.price}
                                            data-color={item.color}
                                            onClick={() =>
                                              this.ChangeColor(6, item.color)
                                            }
                                          ></button>
                                        </div>
                                      )
                                    )
                                  )
                                : null)
                            }
                          </div>
                          <JsColor
                            id="accordion-item-6"
                            label="Message background color"
                            onChange={this.handleColorDoorShape}
                          />
                          <div className="row row-cols-3 mt-4 row-without-top">
                            {
                              ((dataPosition = 0),
                              this.state.menu.doors_shapes !== undefined &&
                              this.state.menu.doors_shapes !== null &&
                              this.state.menu.doors_shapes !== ""
                                ? this.state.menu.doors_shapes.map(
                                    (item) => (
                                      dataPosition++,
                                      dataPosition === 1 &&
                                      this.state.change === false
                                        ? localStorage.setItem(
                                            "door-shapes",
                                            item.id
                                          )
                                        : null,
                                      (
                                        <div
                                          className="col mb-3 text-center pl-0"
                                          key={item.id}
                                        >
                                          <button
                                            type="button"
                                            className={
                                              dataPosition === 1
                                                ? "btn swatch sw-door_shapes-btn active"
                                                : "btn swatch sw-door_shapes-btn"
                                            }
                                            style={{
                                              backgroundImage: `url(${item.picture})`,
                                              backgroundSize: "contain",
                                              backgroundPosition: "center",
                                              width: "70px",
                                              height: "70px",
                                              backgroundRepeat: "no-repeat",
                                            }}
                                            data-door_position={item.id}
                                            data-price={item.price}
                                            onClick={() =>
                                              this.ChangeColor(7, item.id)
                                            }
                                          ></button>
                                          <p className="text-center mt-2 mb-0">
                                            {item.price}
                                          </p>
                                        </div>
                                      )
                                    )
                                  )
                                : null)
                            }
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </Accordion>
              ) : null}
            </div>
          </div>
        </div>
        <p
          className="w-100 mx-0 mb-0 text-center"
          id="footer"
          style={footer_border}
        >
          <Link
            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/privacypolicy`}
            style={footer_style}
          >
            <strong>Datenschutzerklärung</strong>
          </Link>
          <strong className="mx-3 text-center" style={footer_style}>
            |
          </strong>
          <Link
            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/impressum`}
            style={footer_style}
          >
            <strong>Impressum</strong>
          </Link>
        </p>
      </div>
    ) : (
      <div className="container-fluid my-5">
        <div className="row w-100 mx-0">
          <div className="col-10 mx-auto mt-5">
            <p className="display-4 text-center">
              <Link
                to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}
                className="text-dark"
              >
                Wait ... <br /> Go to HomePage{" "}
                <i className="fas fa-arrow-right"></i>
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Configurator.propTypes = {
  project: PropTypes.object.isRequired,
  getProjectHomepage: PropTypes.func.isRequired,
  configurator: PropTypes.object.isRequired,
  getProjectHouseConfigurator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  configurator: state.configurator.configurator,
});

export default connect(mapStateToProps, {
  getProjectHomepage,
  getProjectHouseConfigurator,
})(Configurator);
