import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";

class Impressum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            logo: "",
            impressum_company: ""
        }
    }
    
    UNSAFE_componentWillMount() {
        this.props.getProjectHomepage(this.state.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        const {
            logo,
            impressum_company
        } = nextProps.project;
        this.setState({ logo, impressum_company });
    }

    render() {
        return (
            <div className="mx-0 pt-4" id="impressum">
                <Link to={`${process.env.PUBLIC_URL}/${this.state.id}`}>
                    <img src={this.state.logo} alt="Logo" id="logo-impressum"/>
                </Link>
                <div className="col-3 mx-auto pt-5 px-0 mt-5">
                    <div className="card p-3 mt-5">
                        {
                            this.state.impressum_company[0] !== undefined ?
                            (
                                <div className="card-body">
                                    <p><b>{this.state.impressum_company[0].company}</b></p>
                                    <p>{this.state.impressum_company[0].street} {this.state.impressum_company[0].nr}, {this.state.impressum_company[0].postcode} {this.state.impressum_company[0].city}</p>
                                    <p>{this.state.impressum_company[0].leader_title}: {this.state.impressum_company[0].leader_names}</p>
                                    <p>{this.state.impressum_company[0].country}</p>
                                    <p>{this.state.impressum_company[0].registration}</p>
                                    <p>VAT-ID: {this.state.impressum_company[0].vat_tax_no}</p>
                                    <p><i className="fas fa-phone mr-2"></i> {this.state.impressum_company[0].phone}</p>
                                    <p><i className="fas fa-envelope mr-2"></i> <a href="mailto:bauvorschau@supergrundriss.de">{this.state.impressum_company[0].email}</a></p>
                                    <p>{this.state.impressum_company[0].other_legals}</p>
                                </div>
                            )
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Impressum.propTypes = {
    project: PropTypes.object.isRequired,
    getProjectHomepage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    project: state.project.project
});

export default connect(
    mapStateToProps,
    { getProjectHomepage }
)(Impressum);