import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjectFiles, getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import IntroPresentation from "../pages/IntroPresentations";
import styled from "styled-components";
import axios from "axios";
import { FaBars, FaTimes } from "react-icons/fa";
import "./layout.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      house_name: this.props.match.params.house,
      planset_id: this.props.match.params.id,
      client_colors: "",
      order_info: "",
      house_orders_configurator: "",
      order_status: "",
      exterior_subcategories: "",
      exterior_videos: "",
      interior_videos: "",
      interior_subcategories: "",
      exterior_360_panorams: "",
      interior_360_panorams: "",
      configurator_2d_subcategories: "",
      menuOpen: false,
      exterior: false,
      interior: false,
      panoramsExterior: false,
      panoramsInterior: false,
      configuratorExterior: false,
      exteriorNormalTour: false,
      interiorNormalTour: false,
      loading: "",
      logo: "",
      first_image: "",
      message: "",
      exterior_img: "",
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        arrows: false,
      },
    };
    this.openMenu = this.openMenu.bind(this);
    this.openExterior = this.openExterior.bind(this);
    this.openExteriorNormalTour = this.openExteriorNormalTour.bind(this);
    this.openInteriorNormalTour = this.openInteriorNormalTour.bind(this);
    this.openInterior = this.openInterior.bind(this);
    this.openPanoramsExterior = this.openPanoramsExterior.bind(this);
    this.openPanoramsInterior = this.openPanoramsInterior.bind(this);
    this.openConfiguratorExterior = this.openConfiguratorExterior.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.removeScroll = this.removeScroll.bind(this);
    this.handleToUpdate.bind(this);
    this.handleToCloseAll.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const {
      client_colors,
      exterior_subcategories,
      interior_subcategories,
      configurator_2d_subcategories,
      exterior_360_panorams,
      interior_360_panorams,
      exterior_videos,
      interior_videos,
      loading,
      logo,
      first_image,
      message,
      order_info,
      order_status,
      house_orders_configurator,
    } = nextProps.project;
    this.setState({
      client_colors: client_colors,
      exterior_subcategories: exterior_subcategories,
      interior_subcategories: interior_subcategories,
      exterior_360_panorams: exterior_360_panorams,
      interior_360_panorams: interior_360_panorams,
      loading: loading,
      exterior_videos: exterior_videos,
      interior_videos: interior_videos,
      logo: logo,
      first_image: first_image,
      message: message,
      order_info: order_info,
      order_status: order_status,
      house_orders_configurator: house_orders_configurator,
      configurator_2d_subcategories: configurator_2d_subcategories,
    });
    if (
      this.state.exterior_subcategories !== undefined &&
      this.state.exterior_subcategories !== null
    ) {
      if (
        this.state.exterior_subcategories.length > 0 &&
        this.state.exterior_subcategories.length != undefined
      ) {
        var exterior_images = [];
        let positioning = 0;
        this.state.exterior_subcategories.map((exterior_subcategorie) => {
          axios
            .get(
              `https://blue7.it/presentation/beta3/exterior.php?o_id=${this.state.planset_id}&house=${exterior_subcategorie.house}`
            )
            .then((res) => {
              exterior_images[positioning] = res.data;
              positioning++;
              this.setState({ exterior_img: exterior_images });
            });
        });
        // console.log(exterior_images);
      }
    }
    let user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined && user !== null) {
      if (
        this.state.order_info !== undefined &&
        this.state.order_info !== null &&
        this.state.order_info !== ""
      ) {
        if (this.state.order_info.client_email !== "") {
          if (user.email !== this.state.order_info.client_email) {
            localStorage.removeItem("user");
            user = null;
          }
        }
      }
    }
    let redirect = false;
    if (user === undefined || user === null) {
      redirect = true;
    } else {
      redirect = false;
    }
    if (
      this.state.order_status === false &&
      redirect === true &&
      this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== undefined
    ) {
      let referrer = document.referrer;
      if (
        referrer.includes("cseven.eu") ||
        referrer.includes("blue7.it/studio") ||
        referrer.includes("bauvorschau.com") ||
        referrer.includes("streif.bauvorschau.com")
      ) {
      } else {
        this.props.history.push(
          `${process.env.PUBLIC_URL}/${this.props.match.params.id}/login`
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.house !== prevProps.match.params.house) {
      this.props.getProjectHomepage(this.state.planset_id);
      this.setState({ house_name: this.props.match.params.house });
      if (this.props.match.params.id !== prevProps.match.params.id) {
        this.setState({ planset_id: this.props.match.params.id });
      }
    }
  }

  redirect(url) {
    this.props.history.push(url);
  }

  UNSAFE_componentWillMount() {
    this.props.getProjectHomepage(this.state.planset_id);
  }

  handleToUpdate(update) {
    this.setState({ menuOpen: update });
  }

  handleToCloseAll() {
    this.closeAll();
  }

  handleResize = () => {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (window.innerWidth < 992) {
      if (
        document.getElementById("main-menu") !== undefined &&
        document.getElementById("main-menu") !== null
      ) {
        if (document.getElementById("main-menu").classList.contains("opened")) {
          document.getElementById("main-menu").classList.remove("opened");
          document.getElementById("main-menu").classList.add("minimized");
          document.getElementById("body").classList.remove("open");
          document.getElementById("fullScreen").classList.remove("transformed");
          if (isIE) {
            document.getElementById("fullScreen").classList.remove("left90");
          }
        }
      }
    } else {
      if (
        document.getElementById("main-menu") !== undefined &&
        document.getElementById("main-menu") !== null
      ) {
        if (
          !document
            .getElementById("main-menu")
            .classList.contains("minimized") &&
          this.state.menuOpen === true
        ) {
          document.getElementById("main-menu").classList.add("opened");
          document.getElementById("body").classList.add("open");
          document.getElementById("fullScreen").classList.add("transformed");
          if (isIE) {
            document.getElementById("fullScreen").classList.add("left90");
          }
        }
        if (
          document.getElementById("main-menu").classList.contains("minimized")
        ) {
          document.getElementById("main-menu").classList.remove("minimized");
          document.getElementById("main-menu").classList.add("opened");
          document.getElementById("body").classList.add("open");
          document.getElementById("fullScreen").classList.add("transformed");
          if (isIE) {
            document.getElementById("fullScreen").classList.add("left90");
          }
        }
      }
    }
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  openMenu(event) {
    const isFirefox = typeof InstallTrigger !== "undefined";
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"]);
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    event.preventDefault();
    this.setState({ menuOpen: !this.state.menuOpen });
    if (this.state.menuOpen === false) {
      if (window.innerWidth > 992) {
        if (
          document.getElementById("body") !== undefined &&
          document.getElementById("body") !== null
        ) {
          document.getElementById("body").classList.add("open");
        }
        if (
          document.getElementById("fullScreen") !== null &&
          document.getElementById("fullScreen") !== undefined
        ) {
          document.getElementById("fullScreen").classList.add("transformed");
          if (isIE || isFirefox || isSafari || isEdge) {
            document.getElementById("fullScreen").classList.add("left90");
          }
        }
      }
    } else {
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      document.getElementById("fullScreen").classList.remove("transformed");
      if (isIE || isFirefox || isSafari || isEdge) {
        document.getElementById("fullScreen").classList.remove("left90");
      }
      setTimeout(() => {
        document.getElementById("body").classList.remove("open");
      }, 700);
    }
  }

  openExterior() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.exterior === false) {
      document.body.classList.add("nav-active2");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      this.setState({ interior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ exterior: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active2");
      this.setState({ exterior: false });
    }
  }

  openExteriorNormalTour() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.exterior === false) {
      document.body.classList.add("nav-active9");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active10");
      this.setState({ interior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ exterior: false });
      this.setState({ exteriorNormalTour: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active9");
      this.setState({ exterior: false });
    }
  }

  openInterior() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.interior === false) {
      document.body.classList.add("nav-active");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      this.setState({ exterior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ interior: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active");
      this.setState({ interior: false });
    }
  }

  openInteriorNormalTour() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.interiorNormalTour === false) {
      document.body.classList.add("nav-active10");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active");
      this.setState({ exterior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ interior: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ interiorNormalTour: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active10");
      this.setState({ interiorNormalTour: false });
    }
  }

  openPanoramsExterior() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.panoramsExterior === false) {
      document.body.classList.add("nav-active4");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      this.setState({ interior: false });
      this.setState({ exterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ panoramsExterior: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active4");
      this.setState({ panoramsExterior: false });
    }
  }

  openPanoramsInterior() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.add("normal");
    }
    if (this.state.panoramsInterior === false) {
      document.body.classList.add("nav-active5");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active8");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      this.setState({ interior: false });
      this.setState({ exterior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ configuratorExterior: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ panoramsInterior: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active5");
      this.setState({ panoramsInterior: false });
    }
  }

  openConfiguratorExterior() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      document.getElementById("body").classList.remove("normal");
    }
    if (this.state.configuratorExterior === false) {
      document.body.classList.add("nav-active8");
      document.body.classList.remove("nav-active");
      document.body.classList.remove("nav-active2");
      document.body.classList.remove("nav-active4");
      document.body.classList.remove("nav-active5");
      document.body.classList.remove("nav-active9");
      document.body.classList.remove("nav-active10");
      this.setState({ interior: false });
      this.setState({ exterior: false });
      this.setState({ panoramsExterior: false });
      this.setState({ panoramsInterior: false });
      this.setState({ exteriorNormalTour: false });
      this.setState({ interiorNormalTour: false });
      this.setState({ configuratorExterior: true });
      if (
        document.getElementById("main-menu").classList.contains("minimized")
      ) {
        document.getElementById("main-menu").classList.remove("minimized");
      }
    } else {
      document.body.classList.remove("nav-active8");
      this.setState({ configuratorExterior: false });
    }
  }

  closeAll() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null &&
      document.getElementById("body") !== ""
    ) {
      if (document.getElementById("body").classList.contains("normal")) {
        document.getElementById("body").style.overflowY = "hidden";
      } else {
        document.getElementById("body").style.overflowY = "scroll";
      }
    }
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    document.body.classList.remove("nav-active5");
    document.body.classList.remove("nav-active2");
    document.body.classList.remove("nav-active");
    document.body.classList.remove("nav-active4");
    document.body.classList.remove("nav-active8");
    document.body.classList.remove("nav-active9");
    document.body.classList.remove("nav-active10");
    this.setState({ interior: false });
    this.setState({ exterior: false });
    this.setState({ panoramsExterior: false });
    this.setState({ panoramsInterior: false });
    this.setState({ exteriorNormalTour: false });
    this.setState({ interiorNormalTour: false });
    this.setState({ configuratorExterior: false });
    if (
      document.getElementById("fullScreen") !== null &&
      document.getElementById("fullScreen") !== undefined
    ) {
      document.getElementById("fullScreen").classList.remove("transformed");
      if (isIE) {
        document.getElementById("fullScreen").classList.remove("left360");
      }
    }
    setTimeout(() => {
      if (
        document.getElementById("body") !== null &&
        document.getElementById("body") !== undefined
      ) {
        document.getElementById("body").classList.remove("open");
      }
    }, 700);
    document.getElementById("main-menu").classList.remove("opened");
    document.getElementById("main-menu").classList.remove("minimized");
    this.setState({ menuOpen: false });
    if (
      document.getElementById("slider") !== null &&
      document.getElementById("slider") !== undefined
    ) {
      if (!document.getElementById("slider").classList.contains("pano")) {
        if (document.getElementById("slider").classList.contains("open")) {
          document.getElementById("slider").classList.remove("open");
          let list = document.getElementsByClassName(
            "col-md-6 col-12 my-3 slide openSlider rounded open"
          );
          for (var i = 0; i < list.length; i++) {
            if (list[i].classList.contains("open")) {
              list[i].classList.remove("open");
            }
          }
          document.getElementById("menu-button").classList.remove("d-none");
        }
      }
    }
    this.setState({ house: this.props.match.params.house });
  }

  removeScroll() {
    if (
      document.getElementById("body") !== undefined &&
      document.getElementById("body") !== null
    ) {
      document.getElementById("body").style.overflowY = "hidden";
    }
  }

  logOut() {
    localStorage.removeItem("user");
    let user = JSON.parse(localStorage.getItem("user"));
    if (this.state.order_status === false) {
      let referrer = document.referrer;
      if (
        referrer.includes("cseven.eu") ||
        referrer.includes("blue7.it/studio") ||
        referrer.includes("bauvorschau.com") ||
        referrer.includes("streif.bauvorschau.com")
      ) {
      } else {
        this.props.history.push(
          `${process.env.PUBLIC_URL}/${this.props.match.params.id}/login`
        );
      }
    } else {
      this.props.history.push(`${this.props.match.params.id}`);
    }
  }

  render() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isFirefox = typeof InstallTrigger !== "undefined";
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"]);
    const isEdge = !isIE && !!window.StyleMedia;
    if (isIE || isFirefox || isSafari || isEdge) {
      if (
        document.getElementById("fullScreen") !== undefined &&
        document.getElementById("fullScreen") !== null
      ) {
        document.getElementById("fullScreen").classList.add("left90");
        document.getElementById("fullScreen").classList.remove("left90");
      }
    }
    let obj_interior = this.state.interior_subcategories;
    let obj_exterior = this.state.exterior_subcategories;
    let obj_interior_panorams = this.state.interior_360_panorams;
    let obj_exterior_panorams = this.state.exterior_360_panorams;
    let obj_exterior_configurator = this.state.configurator_2d_subcategories;
    let interior_menu = [];
    let exterior_menu = [];
    let interior_panorams_menu = [];
    let exterior_panorams_menu = [];
    let exterior_configurator_menu = [];
    let interior_items = [];
    let exterior_items = [];
    let interior_panorams_items = [];
    let exterior_panorams_items = [];
    let exterior_configurator_items = [];
    let exterior_numbering = 0;
    Object.size = function (obj, menu) {
      var size = 0,
        key;
      if (size === 0) {
      }
      for (key in obj) {
        menu.push(key);
        if (obj.hasOwnProperty(key)) size++;
      }
    };
    if (obj_interior !== null && obj_interior !== undefined) {
      Object.size(obj_interior, interior_menu);
      interior_menu.map((interior) =>
        interior_items.push(this.state.interior_subcategories[interior])
      );
    }
    if (obj_exterior !== null && obj_exterior !== undefined) {
      Object.size(obj_exterior, exterior_menu);
      exterior_menu.map((exterior) =>
        exterior_items.push(this.state.exterior_subcategories[exterior])
      );
    }
    if (obj_interior_panorams !== null && obj_interior_panorams !== undefined) {
      Object.size(obj_interior_panorams, interior_panorams_menu);
      interior_panorams_menu.map((interior_panorams) => {
        interior_panorams_items.push(
          this.state.interior_360_panorams[interior_panorams]
        );
        return true;
      });
    }
    if (obj_exterior_panorams !== null && obj_exterior_panorams !== undefined) {
      Object.size(obj_exterior_panorams, exterior_panorams_menu);
      exterior_panorams_menu.map((exterior_panorams) => {
        exterior_panorams_items.push(
          this.state.exterior_360_panorams[exterior_panorams]
        );
        return true;
      });
    }
    if (
      obj_exterior_configurator !== null &&
      obj_exterior_configurator !== undefined
    ) {
      Object.size(obj_exterior_configurator, exterior_configurator_menu);
      exterior_configurator_menu.map((exterior_configurators) => {
        exterior_configurator_items.push(
          this.state.configurator_2d_subcategories[exterior_configurators]
        );
      });
    }
    let styles = {
      color: "#333",
      cursor: "pointer",
    };
    let styles_link = {
      color: "#333",
      fill: "#ur8o3u",
      cursor: "pointer",
    };
    let A = styled.a`
      font-size: 25px;
      color: #000;
      cursor: pointer;
      :before {
        background: #fff;
      }
      &:hover {
        color: #000 !important;
      }
      &:hover:before {
        right: 0 !important;
      }
      &:hover:active::before {
        right: 0 !important;
      }
      &:hover:focus::before {
        right: 0 !important;
      }
    `;
    if (
      this.state.client_colors !== undefined &&
      this.state.client_colors !== null
    ) {
      document.body.style.backgroundColor =
        this.state.client_colors.color_5 !== ""
          ? this.state.client_colors.color_5
          : "#fff";
      styles = {
        color:
          this.state.client_colors.color_1 !== ""
            ? this.state.client_colors.color_1
            : "#333",
        cursor: "pointer",
      };
      styles_link = {
        fill:
          this.state.client_colors.color_2 !== ""
          ? this.state.client_colors.color_2
          : "#333",
        color:
          this.state.client_colors.color_2 !== ""
            ? this.state.client_colors.color_2
            : "#333",
        cursor: "pointer",
      };
      if (
        this.state.client_colors.color_2 !== "" &&
        this.state.client_colors.color_3 !== ""
      ) {
        A = styled.a`
          font-size: 25px;
          color: ${this.state.client_colors.color_2};
          cursor: pointer;
          :before {
            background: ${this.state.client_colors.color_1a};
          }
          &:hover {
            color: ${this.state.client_colors.color_1a}!important;
          }
          &:hover:before {
            right: 0 !important;
          }
          &:hover:active::before {
            right: 0 !important;
          }
          &:hover:focus::before {
            right: 0 !important;
          }
        `;
      } else {
        A = styled.a`
          font-size: 25px;
          color: #1a9a20;
          cursor: pointer;
          :before {
            background: #1a9a20;
          }
          &:hover {
            color: #1a9a20 !important;
          }
          &:hover:before {
            right: 0 !important;
          }
          &:hover:active::before {
            right: 0 !important;
          }
          &:hover:focus::before {
            right: 0 !important;
          }
        `;
      }
    }
    var resArrInterior_items = [];
    var resArrExterior_items = [];
    var resArrInterior_panoramas = [];
    var resArrExterior_panoramas = [];
    var resArrExterior_configurator = [];
    var handleToUpdate = this.handleToUpdate;
    if (this.state.order_info !== undefined) {
      document.title =
        this.state.order_info.order_name !== undefined &&
        this.state.order_info.order_name !== null
          ? this.state.order_info.order_name
          : null;
    }
    let user = JSON.parse(localStorage.getItem("user"));
    let redirect = false;
    if (user === undefined || user === null) {
      redirect = true;
    } else {
      redirect = false;
    }
    if (
      this.state.order_status === false &&
      redirect === true &&
      this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== undefined
    ) {
      let referrer = document.referrer;
      if (
        referrer.includes("cseven.eu") ||
        referrer.includes("blue7.it/studio") ||
        referrer.includes("bauvorschau.com") ||
        referrer.includes("streif.bauvorschau.com")
      ) {
      } else {
        this.props.history.push(
          `${process.env.PUBLIC_URL}/${this.props.match.params.id}/login`
        );
      }
    }
    let order_name = "Order Name";
    if (this.state.order_info !== undefined && this.state.order_info !== null) {
      order_name = this.state.order_info.order_name;
    }
    const parser = new DOMParser();
    const decoded_order_name = parser.parseFromString(
      `<!doctype html><body>${order_name}`,
      "text/html"
    ).body.textContent;
    let logoStyles;
    if (this.state.planset_id === "4069") {
      logoStyles = {
        maxHeight: "150px",
        maxWidht: "550px",
      };
    }
    return (
      <>
        <Helmet>
          <title>
            {this.state.order_info !== undefined
              ? this.state.order_info.order_name !== undefined &&
                this.state.order_info.order_name !== null
                ? this.state.order_info.order_name
                : null
              : null}
          </title>
        </Helmet>
        <div
          id={
            this.state.house_name !== null &&
            this.state.house_name !== undefined &&
            this.state.house_name !== ""
              ? null
              : "body1"
          }
        >
          <div className="row w-100 mx-0 p-0">
            <button id="menu-button" className="btn 2" onClick={this.openMenu}>
              <span>{this.state.menuOpen ? <FaTimes /> : <FaBars />}</span>
            </button>
            {
              (interior_items.forEach(function (item) {
                var i = resArrInterior_items.findIndex(
                  (x) => x.house === item.house
                );
                if (i <= -1) {
                  resArrInterior_items.push({
                    o_id: item.o_id,
                    house: item.house,
                    interior_link: item.interior_link,
                    interior_img_subcateg: item.interior_img_subcateg,
                    interior_img_subcateg_name: item.interior_img_subcateg_name,
                  });
                }
              }),
              resArrInterior_items.length > 0 ? (
                <React.Fragment>
                  <div className="nav" id="interior">
                    <div className="nav__content">
                      <h2>
                        {decoded_order_name} <br />{" "}
                        <p className="mb-0 menu-sub-title">Innen</p>{" "}
                      </h2>
                      {/* <h2>Innen</h2> */}
                      <ul className="nav__list">
                        {resArrInterior_items.map((interior) => (
                          <li
                            className="nav__list-item interior"
                            key={interior.house}
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${interior.house}`}
                              onClick={this.closeAll}
                            >
                              <div className="card">
                                <img
                                  className="card-img-top border-bottom"
                                  alt="Interior image"
                                  src={interior.interior_img_subcateg}
                                ></img>
                                {interior.interior_img_subcateg_name !==
                                  undefined &&
                                interior.interior_img_subcateg_name !== null &&
                                interior.interior_img_subcateg_name !== "" ? (
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {interior.interior_img_subcateg_name}
                                    </h5>
                                  </div>
                                ) : null}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="nav" id="interiorNormalTour">
                    <div className="nav__content">
                      <h2>
                        {decoded_order_name} <br />{" "}
                        <p className="mb-0 menu-sub-title">Innen Normal Tour</p>{" "}
                      </h2>
                      {/* <h2>Innen</h2> */}
                      <ul className="nav__list">
                        {resArrInterior_items.map((interior) => (
                          <li
                            className="nav__list-item interior"
                            key={interior.house}
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/normal_tour/${interior.house}`}
                              onClick={this.closeAll}
                            >
                              <div className="card">
                                <img
                                  className="card-img-top border-bottom"
                                  alt="Interior normalTour image"
                                  src={interior.interior_img_subcateg}
                                ></img>
                                {interior.interior_img_subcateg_name !==
                                  undefined &&
                                interior.interior_img_subcateg_name !== null &&
                                interior.interior_img_subcateg_name !== "" ? (
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {interior.interior_img_subcateg_name}
                                    </h5>
                                  </div>
                                ) : null}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </React.Fragment>
              ) : null)
            }
            {
              (exterior_configurator_items.forEach(function (item) {
                var i = resArrExterior_configurator.findIndex(
                  (x) => x.house === item.house
                );
                if (i <= -1) {
                  resArrExterior_configurator.push({
                    o_id: item.o_id,
                    house: item.house,
                    sub_title: item.sub_title,
                    path: item.path,
                  });
                }
              }),
              resArrExterior_configurator.length > 0 ? (
                <div className="nav" id="configuratorExterior">
                  <div className="nav__content">
                    <h2>
                      {decoded_order_name} <br />{" "}
                      <p className="mb-0 menu-sub-title">Außen Konfigurator</p>{" "}
                    </h2>
                    {/* <h2>Außen Konfigurator</h2> */}
                    <ul className="nav__list">
                      {resArrExterior_configurator.map((configurator) => (
                        <li
                          className="nav__list-item configurator"
                          key={configurator.house}
                        >
                          <Link
                            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/2d_configurator/${configurator.house}/${this.state.house_orders_configurator.ho_id}`}
                            onClick={this.closeAll}
                          >
                            <div className="card">
                              <img
                                className="card-img-top border-bottom"
                                alt="Configurator image"
                                src={configurator.path}
                              ></img>
                              {configurator.sub_title !== undefined &&
                              configurator.sub_title !== null &&
                              configurator.sub_title !== "" ? (
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {configurator.sub_title}
                                  </h5>
                                </div>
                              ) : null}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null)
            }
            {
              (exterior_items.forEach(function (item) {
                var i = resArrExterior_items.findIndex(
                  (x) => x.house === item.house
                );
                if (i <= -1) {
                  resArrExterior_items.push({
                    o_id: item.o_id,
                    house: item.house,
                    exterior_link: item.exterior_link,
                    exterior_img_subcateg: item.exterior_img_subcateg,
                    exterior_img_subcateg_name: item.exterior_img_subcateg_name,
                  });
                }
              }),
              resArrExterior_items.length > 0 ? (
                <React.Fragment>
                  <div className="nav" id="exteriorNormalTour">
                    <div className="nav__content">
                      <h2>
                        {decoded_order_name} <br />{" "}
                        <p className="mb-0 menu-sub-title">Außen Normal Tour</p>{" "}
                      </h2>
                      {/* <h2>Außen</h2> */}
                      <ul className="nav__list">
                        {resArrExterior_items.map((exterior) => (
                          <li
                            className="nav__list-item exterior"
                            key={exterior.house}
                            style={{ width: "320px", height: "180px" }}
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/normal_tour/${exterior.house}`}
                              onClick={this.closeAll}
                            >
                              <div className="card">
                                <img
                                  alt="Exterior Image"
                                  className="card-img-top border-bottom"
                                  src={exterior.exterior_img_subcateg}
                                ></img>
                                {exterior.exterior_img_subcateg_name !==
                                  undefined &&
                                exterior.exterior_img_subcateg_name !== null &&
                                exterior.exterior_img_subcateg_name !== "" ? (
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {exterior.exterior_img_subcateg_name}
                                    </h5>
                                  </div>
                                ) : null}
                              </div>
                            </Link>
                            <div className="d-none">{exterior_numbering++}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="nav" id="exterior">
                    <div className="nav__content">
                      <h2>
                        {decoded_order_name} <br />{" "}
                        <p className="mb-0 menu-sub-title">Außen</p>{" "}
                      </h2>
                      {/* <h2>Außen</h2> */}
                      <ul className="nav__list">
                        {resArrExterior_items.map((exterior) => (
                          <li
                            className="nav__list-item exterior"
                            key={exterior.house}
                            style={{ width: "320px", height: "180px" }}
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${exterior.house}`}
                              onClick={this.closeAll}
                            >
                              <div className="card">
                                <img
                                  alt="Exterior image"
                                  className="card-img-top border-bottom"
                                  src={exterior.exterior_img_subcateg}
                                ></img>
                                {exterior.exterior_img_subcateg_name !==
                                  undefined &&
                                exterior.exterior_img_subcateg_name !== null &&
                                exterior.exterior_img_subcateg_name !== "" ? (
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {exterior.exterior_img_subcateg_name}
                                    </h5>
                                  </div>
                                ) : null}
                              </div>
                            </Link>
                            <div className="d-none">{exterior_numbering++}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </React.Fragment>
              ) : null)
            }
            {
              (exterior_panorams_items.forEach(function (item) {
                var i = resArrExterior_panoramas.findIndex(
                  (x) => x.house === item.house
                );
                if (i <= -1) {
                  resArrExterior_panoramas.push({
                    o_id: item.o_id,
                    house: item.house,
                    panorams_exterior_link: item.panorams_exterior_link,
                    panoram_exterior_img_subcateg:
                      item.panoram_exterior_img_subcateg,
                    panoram_exterior_img_subcateg_name:
                      item.panoram_exterior_img_subcateg_name,
                  });
                }
              }),
              resArrExterior_panoramas.length > 0 ? (
                <div className="nav" id="panoramsExterior">
                  <div className="nav__content">
                    <h2>
                      {decoded_order_name} <br />{" "}
                      <p className="mb-0 menu-sub-title">Außen Panorama Tour</p>{" "}
                    </h2>
                    {/* <h2>Außen Panorama Tour</h2> */}
                    <ul className="nav__list">
                      {resArrExterior_panoramas.map((exterior_panorams) => (
                        <li
                          className="nav__list-item panoramsExterior"
                          key={exterior_panorams.house}
                        >
                          <Link
                            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/panorama_tour/${exterior_panorams.house}`}
                            onClick={this.closeAll}
                          >
                            <div className="card">
                              <img
                                alt="Exterior panorama image"
                                className="card-img-top border-bottom"
                                src={
                                  exterior_panorams.panoram_exterior_img_subcateg
                                }
                              ></img>
                              {exterior_panorams.panoram_exterior_img_subcateg_name !==
                                undefined &&
                              exterior_panorams.panoram_exterior_img_subcateg_name !==
                                null &&
                              exterior_panorams.panoram_exterior_img_subcateg_name !==
                                "" ? (
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {
                                      exterior_panorams.panoram_exterior_img_subcateg_name
                                    }
                                  </h5>
                                </div>
                              ) : null}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null)
            }
            {
              (interior_panorams_items.forEach(function (item) {
                var i = resArrInterior_panoramas.findIndex(
                  (x) => x.house === item.house
                );
                if (i <= -1) {
                  resArrInterior_panoramas.push({
                    o_id: item.o_id,
                    house: item.house,
                    panorams_interior_link: item.panorams_interior_link,
                    panoram_interior_img_subcateg:
                      item.panoram_interior_img_subcateg,
                    panoram_interior_img_subcateg_name:
                      item.panoram_interior_img_subcateg_name,
                  });
                }
              }),
              resArrInterior_panoramas.length > 0 ? (
                <div className="nav" id="panoramsInterior">
                  <div className="nav__content">
                    <h2>
                      {decoded_order_name} <br />{" "}
                      <p className="mb-0 menu-sub-title">Innen Panorama Tour</p>{" "}
                    </h2>
                    {/* <h2>Innen Panorama Tour</h2> */}
                    <ul className="nav__list">
                      {resArrInterior_panoramas.map((interior_panorams) => (
                        <li
                          className="nav__list-item panoramsInterior"
                          key={interior_panorams.house}
                        >
                          <Link
                            to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/panorama_tour/${interior_panorams.house}`}
                            onClick={this.closeAll}
                          >
                            <div className="card">
                              <img
                                alt="Interior panorama image"
                                className="card-img-top border-bottom"
                                src={
                                  interior_panorams.panoram_interior_img_subcateg
                                }
                              ></img>
                              {interior_panorams.panoram_interior_img_subcateg_name !==
                                undefined &&
                              interior_panorams.panoram_interior_img_subcateg_name !==
                                null &&
                              interior_panorams.panoram_interior_img_subcateg_name !==
                                "" ? (
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {
                                      interior_panorams.panoram_interior_img_subcateg_name
                                    }
                                  </h5>
                                </div>
                              ) : null}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null)
            }
            <div
              className={
                this.state.menuOpen
                  ? window.innerWidth > 992
                    ? "d-flex flex-column justify-content-center pb-5 mb-5 opened"
                    : "d-flex flex-column justify-content-center pb-5 mb-5 minimized"
                  : "d-flex flex-column justify-content-center pb-5 mb-5"
              }
              id="main-menu"
            >
              <div className="logo2" id="logo2">
                <Link
                  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}
                  onClick={this.closeAll}
                >
                  <img
                    src={this.state.logo}
                    alt="logotype"
                    className="logo"
                    style={logoStyles}
                  ></img>
                </Link>
              </div>
              <ul id="navigation" className={isIE ? "d-block mx-auto" : null}>
                <li className="navigation-item" id="home">
                  {this.state.house_name !== null &&
                  this.state.house_name !== undefined &&
                  this.state.house_name !== "" ? (
                    <Link
                      to={`${process.env.PUBLIC_URL}/${this.state.planset_id}`}
                      className="navigation-link"
                      style={styles_link}
                      onClick={this.closeAll}
                    >
                      Startseite
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </Link>
                  ) : (
                    <A
                      onClick={this.closeAll}
                      className="navigation-link"
                      style={styles_link}
                    >
                      Startseite
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </A>
                  )}
                </li>
                {(exterior_items !== null &&
                  exterior_items !== undefined &&
                  exterior_items.length > 0) ||
                (exterior_panorams_items !== null &&
                  exterior_panorams_items !== undefined &&
                  exterior_panorams_items.length > 0) ||
                (this.state.exterior_videos !== null &&
                  this.state.exterior_videos !== undefined &&
                  this.state.exterior_videos.length > 0) ? (
                  
                 <li
                   className="navigation-item navigation-dropdown"
                   id="auben"
                 >
                   <a
                     className="navigation-link menu-icon2"
                     style={{ ...styles, cursor: "auto" }}
                   >
                     Außen :
                     <span>
                       <i className="fas fa-caret-left float-right pr-5"></i>
                     </span>
                   </a>
                 </li>
                ) : null}
                {resArrExterior_items.length > 0 ? (
                  <React.Fragment>
                    {/* <li className="navigation-item navigation-dropdown">
                      <A
                        onClick={
                          resArrExterior_items.length > 1
                            ? this.openExterior
                            : () => {
                                resArrExterior_items.map(
                                  (exterior_item) => (
                                    this.props.history.push(
                                      `${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${exterior_item.house}`
                                    ),
                                    this.closeAll()
                                  )
                                );
                              }
                        }
                        className="navigation-link menu-icon2 ml-5"
                        style={styles_link}
                      >
                        - Normal
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li> */}
                    <li className="navigation-item navigation-dropdown">
                      <A
                        onClick={
                          resArrExterior_items.length > 1
                            ? this.openExteriorNormalTour
                            : () => {
                                resArrExterior_items.map(
                                  (exterior_item) => (
                                    this.props.history.push(
                                      `${process.env.PUBLIC_URL}/${this.state.planset_id}/normal_tour/${exterior_item.house}`
                                    ),
                                    this.closeAll()
                                  )
                                );
                              }
                        }
                        className="navigation-link menu-icon2 ml-5"
                        style={styles_link}
                      >
                        - Normaltour
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li>
                  </React.Fragment>
                ) : null}
                {resArrExterior_panoramas.length > 0 ? (
                  <li className="navigation-item">
                    <A
                      onClick={
                        resArrExterior_panoramas.length > 1
                          ? this.openPanoramsExterior
                          : () => {
                              resArrExterior_panoramas.map(
                                (exterior_panoram) => (
                                  // eslint-disable-next-line
                                  this.props.history.push(
                                    `${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${exterior_panoram.house}`
                                  ),
                                  this.closeAll()
                                )
                              );
                            }
                      }
                      className="navigation-link menu-icon4 ml-5"
                      style={styles_link}
                    >
                      - Panoramatour{" "}
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </A>
                  </li>
                ) : null}
                {this.state.exterior_videos !== null &&
                this.state.exterior_videos !== undefined ? (
                  this.state.exterior_videos.length > 0 ? (
                    <li className="navigation-item">
                      <A
                        onClick={() => (
                          // eslint-disable-next-line
                          this.props.history.push(
                            `${process.env.PUBLIC_URL}/${this.state.planset_id}/videos/x`
                          ),
                          this.closeAll()
                        )}
                        className="navigation-link menu-icon4 ml-5"
                        style={styles_link}
                      >
                        - Videos{" "}
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li>
                  ) : null
                ) : null}
                {resArrExterior_configurator.length > 0 ? (
                  <li className="navigation-item navigation-dropdown">
                    <A
                      onClick={
                        resArrExterior_configurator.length > 1
                          ? this.openConfiguratorExterior
                          : () => {
                              resArrExterior_configurator.map(
                                (exterior_configurator_item) => (
                                  this.props.history.push(
                                    `${process.env.PUBLIC_URL}/${this.state.planset_id}/2d_configurator/${exterior_configurator_item.house}/${this.state.house_orders_configurator.ho_id}`
                                  ),
                                  this.closeAll()
                                )
                              );
                            }
                      }
                      className="navigation-link menu-icon2 ml-5"
                      style={styles_link}
                    >
                      - Konfigurator
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </A>
                  </li>
                ) : null}
                {(interior_items !== null &&
                  interior_items !== undefined &&
                  interior_items.length > 0) ||
                (interior_panorams_items !== null &&
                  interior_panorams_items !== undefined &&
                  interior_panorams_items.length > 0) ||
                (this.state.interior_videos !== null &&
                  this.state.interior_videos !== undefined &&
                  this.state.interior_videos.length > 0) ? (
                  <li
                    className="navigation-item navigation-dropdown"
                    id="innen"
                  >
                    <a
                      className="navigation-link menu-icon2"
                      style={{ ...styles, cursor: "auto" }}
                    >
                      Innen :
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </a>
                  </li>
                ) : null}
                {resArrInterior_items.length > 0 ? (
                  <React.Fragment>
                    {/* <li className="navigation-item navigation-dropdown">
                      <A
                        onClick={
                          resArrInterior_items.length > 1
                            ? this.openInterior
                            : () => {
                                resArrInterior_items.map(
                                  (interior_item) => (
                                    // eslint-disable-next-line
                                    this.props.history.push(
                                      `${process.env.PUBLIC_URL}/${this.state.planset_id}/images/${interior_item.house}`
                                    ),
                                    this.closeAll()
                                  )
                                );
                              }
                        }
                        className="navigation-link menu-icon ml-5"
                        style={styles_link}
                      >
                        - Normal
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li> */}
                    <li className="navigation-item navigation-dropdown">
                      <A
                        onClick={
                          resArrInterior_items.length > 1
                            ? this.openInteriorNormalTour
                            : () => {
                                resArrInterior_items.map(
                                  (interior_item) => (
                                    // eslint-disable-next-line
                                    this.props.history.push(
                                      `${process.env.PUBLIC_URL}/${this.state.planset_id}/normal_tour/${interior_item.house}`
                                    ),
                                    this.closeAll()
                                  )
                                );
                              }
                        }
                        className="navigation-link menu-icon ml-5"
                        style={styles_link}
                      >
                        - Normaltour
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li>
                  </React.Fragment>
                ) : null}
                {resArrInterior_panoramas.length > 0 ? (
                  <li className="navigation-item">
                    <A
                      onClick={
                        resArrInterior_panoramas.length > 1
                          ? this.openPanoramsInterior
                          : () => {
                              resArrInterior_panoramas.map(
                                (interior_panoram) => (
                                  // eslint-disable-next-line
                                  this.props.history.push(
                                    `${process.env.PUBLIC_URL}/${this.props.match.params.id}/panorama_tour/${interior_panoram.house}`
                                  ),
                                  this.closeAll()
                                )
                              );
                            }
                      }
                      className="navigation-link menu-icon5 ml-5"
                      style={styles_link}
                    >
                      - Panoramatour{" "}
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </A>
                  </li>
                ) : null}
                {this.state.interior_videos !== null &&
                this.state.interior_videos !== undefined ? (
                  this.state.interior_videos.length > 0 ? (
                    <li className="navigation-item">
                      <A
                        onClick={() => (
                          // eslint-disable-next-line
                          this.props.history.push(
                            `${process.env.PUBLIC_URL}/${this.state.planset_id}/videos/n`
                          ),
                          this.closeAll()
                        )}
                        className="navigation-link menu-icon4 ml-5"
                        style={styles_link}
                      >
                        - Videos{" "}
                        <span>
                          <i className="fas fa-caret-left float-right pr-5"></i>
                        </span>
                      </A>
                    </li>
                  ) : null
                ) : null}
                {user !== undefined && user !== null ? (
                  <li className="navigation-item">
                    <A
                      onClick={() => this.logOut()}
                      className="navigation-link ml-5"
                      style={styles_link}
                    >
                      - Logout{" "}
                      <span>
                        <i className="fas fa-caret-left float-right pr-5"></i>
                      </span>
                    </A>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          {this.state.house_name !== null &&
          this.state.house_name !== undefined &&
          this.state.house_name !== "" ? null : (
            <IntroPresentation
              handleToUpdate={handleToUpdate.bind(this)}
              idFromParent={this.state.planset_id}
            />
          )}
        </div>
      </>
    );
  }
}

AppNavbar.propTypes = {
  project: PropTypes.object.isRequired,
  getProjectHomepage: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  getProjectFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  files: state.files.files,
  project: state.project.project,
});

export default connect(mapStateToProps, {
  getProjectFiles,
  getProjectHomepage,
})(AppNavbar);
