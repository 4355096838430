import { GET_PROJECT_HOMEPAGE, GET_PROJECT_FILES, GET_PROJECT_FILES360, GET_PROJECT_HOUSE_CONFIGURATOR, } from '../actions/types';

const initialState = {
    project: {},
    files: {},
    files360: {},
    configurator: {}
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_PROJECT_HOMEPAGE: 
            return {
                ...state,
                project: action.getData
            };
        case GET_PROJECT_FILES:
            return {
                ...state,
                files: action.getData
            };
        case GET_PROJECT_FILES360: 
            return {
                ...state,
                files360: action.getData
            };
        case GET_PROJECT_HOUSE_CONFIGURATOR:
            return {
                ...state,
                configurator: action.getData
            };
        default: 
            return state;
    }
}