import { GET_PROJECT_HOMEPAGE, GET_PROJECT_FILES, GET_PROJECT_FILES360, GET_PROJECT_HOUSE_CONFIGURATOR } from "./types";
import axios from "axios";

export const getProjectHomepage = id => async dispatch => {
  await axios
    .get(`https://blue7.it/presentation/beta3/index.php?o_id=${id}`)
    .then((res) => {
      res.data.loading = false;
      dispatch({
        type: GET_PROJECT_HOMEPAGE,
        getData: res.data
      });
    })
};

export const getProjectFiles = (id, house) => async dispatch => {
  if(house.includes("n")) {
      await axios
        .get(`https://blue7.it/presentation/beta3/interior.php?o_id=${id}&house=${house}`)
        .then((res) => {
          dispatch({
            type: GET_PROJECT_FILES,
            getData: res.data
          });
        });
  } else {
      await axios
        .get(`https://blue7.it/presentation/beta3/exterior.php?o_id=${id}&house=${house}`)
        .then((res) => {
          dispatch({
            type: GET_PROJECT_FILES,
            getData: res.data
          });
        });
  }
};


export const getProjectVideos = (id, house) => async dispatch => {
  if(house.includes("n")) {
      await axios
        .get(`https://blue7.it/presentation/beta3/interiorvideo.php?o_id=${id}`)
        .then((res) => {
          dispatch({
            type: GET_PROJECT_FILES,
            getData: res.data
          });
        });
  } else {
      await axios
        .get(`https://blue7.it/presentation/beta3/exteriorvideo.php?o_id=${id}`)
        .then((res) => {
          dispatch({
            type: GET_PROJECT_FILES,
            getData: res.data
          });
        });
  }
};

export const getProjectFiles360 = (id, house) => async dispatch => {
        await axios
          .get(`https://blue7.it/presentation/beta3/panorams.php?o_id=${id}&house=${house}`)
          .then((res) => {
            dispatch({
              type: GET_PROJECT_FILES360,
              getData: res.data
            });
          });
};


export const getProjectHouseConfigurator = (ho_id, house) => async dispatch => {
      await axios
        .get(`https://blue7.it/presentation/beta3/configurator.php?ho_id=${ho_id}&house=${house}`)
        .then((res) => {
          dispatch({
            type: GET_PROJECT_HOUSE_CONFIGURATOR,
            getData: res.data
          });
        });
};