import React,{ Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProjectHomepage } from "../actions/projectActions";
import { Link } from "react-router-dom";

class Login extends Component {
    constructor (props) {
        super(props);
        this.state = {
            projectActive: null,
            userLoggedIn: false,
            email: '',
            password: '',
            client_colors: "",
            submitted: false,
            id: this.props.match.params.id,
            order_info: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps,nextState) {
        const {
            order_info,
            client_colors
        } = nextProps.project;
        this.setState({
            order_info: order_info,
            client_colors: client_colors
        });
    }

    UNSAFE_componentWillMount() {
        this.props.getProjectHomepage(this.props.match.params.id);
    }

    handleChange = (e) => {
        const { name,value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var sha1 = require('sha1');
        if (this.state.order_info.client_pwd === sha1(this.state.password) && this.state.order_info.client_email === this.state.email) {
            this.setState({ submitted: true });
            const { email,password } = this.state;

            if (email !== '' && password !== '') {
                let user = {
                    email: this.state.email,
                    password: this.state.password
                }
                localStorage.setItem('user',JSON.stringify(user));
                this.props.history.push(`${process.env.PUBLIC_URL}/${this.state.id}`);
            }
        }
    }

    render() {
        let footer_style = {
            color: `#24B04D`
        };
        let footer_border = {
            borderColor: `#24B04D`
        }
        if(this.state.client_colors !== undefined && this.state.client_colors !== null) {
            footer_style = {
                color: `${this.state.client_colors.color_2}`
            }
            footer_border = {
                borderColor: `${this.state.client_colors.color_2}`
            }
        }
        const { email,password,submitted } = this.state;
        return (
            <div className="container-fluid d-flex flex-column align-items-center justify-content-center" style={{ height: "100vh" }}>
                <div className="row w-100 mx-0 d-flex justify-content-center">
                    <div className="col-3">
                        <div className="card py-3">
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email address</label>
                                        <input type="email" className="form-control rounded-0" id="email" name="email" value={email} onChange={this.handleChange} />
                                        {
                                            password === '' ? (<small className="text-danger pt-2">Email is required</small>) : null
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" name="password" value={password} onChange={this.handleChange} className="form-control rounded-0" id="password" />
                                        {
                                            password === '' ? (<small className="text-danger pt-2">Password is required</small>) : null
                                        }
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block rounded-0" onClick={this.handleSubmit}>Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="w-100 mx-0 mb-0 text-center" id="footer" style={footer_border}>
                    <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/privacypolicy`} style={footer_style}>
                        <strong>Datenschutzerklärung</strong>
                    </Link>
                    <strong className="mx-3 text-center" style={footer_style}>
                        |
                    </strong>
                    <Link  to={`${process.env.PUBLIC_URL}/${this.state.planset_id}/impressum`} style={footer_style}>
                        <strong>Impressum</strong>
                    </Link>
                </p>
            </div>
        )
    }
}

Login.propTypes = {
    project: PropTypes.object.isRequired,
    getProjectHomepage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    project: state.project.project
});

export default connect(
    mapStateToProps,
    { getProjectHomepage }
)(Login);