import * as React from "react";
import { SVGProps } from "react";

const SvgHousSides = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26.523 18.874"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      
    >
      <g
        
      >
        <path d="M-48.515 21.586h8.202V37.99h-8.202Z"  />
        <path
          d="M-45.075 23.438h1.323v3.704h-1.323zM-45.075 32.17h1.323v3.703h-1.323zM-45.075 27.936h1.323v3.44h-1.323zM-47.721 26.348h.264v7.144h-.264Z"
          fill="#c4c4c4"
        />
      </g>
      <g
        
      >
        <path d="M37.006 9.398h10.318v14.023H37.006Z"  />
        <path d="M37.8 10.457h8.73v11.906H37.8Z" fill="#e5e5e5" />
        <path d="M37.535 16.013h9.26v.794h-9.26Z"  />
        <path d="M41.768 9.928h.794v12.964h-.794z"  />
        <g
          
        >
          <path d="M24.313 13.197h-9.677v-1.759L25.151 8.29Z" fill="#9a9696" />
          <path
            d="m14.636 12.656 9.922-1.19-.264 1.851-9.658 5.557z"
            fill="#9a9696"
          />
          <path
            d="m1.772 13.234-.63-4.414h11.425l2.07 3.09V18.874z"
            fill="#c4c4c4"
          />
          <path
            d="M1.748 6.758c0-.877.71-1.588 1.587-1.588H10.5c.877 0 1.588.711 1.588 1.588V8.49c0 .876-.71 1.587-1.588 1.587H3.335c-.877 0-1.587-.71-1.587-1.587z"
            fill="#c4c4c4"
          />
          <path
            d="M3.15 3.988c0-.738.598-1.336 1.336-1.336h3.81a1.337 1.337 0 0 1 0 2.673h-3.81A1.337 1.337 0 0 1 3.15 3.988zM4.551 1.315h3.68v1.337h-3.68Z"
            fill="#c4c4c4"
          />
          <path
            d="M1.572 8.983c0-.073.06-.133.133-.133h.043v.176h-.176Z"
            fill="#46715b"
          />
          <path d="M.52 8.15h1.052v.876H.521Z" fill="#9a9696" />
          <path d="m5.139.088.928.494-4.482 8.43-.928-.495Z" fill="#9a9696" />
          <path
            d="m10.186 6.085 2.646 3.308v8.68l-2.646-1.14ZM2.249 8.07 4.365 4.1l.285 10.39-2.004-.865Z"
            
          />
          <path
            d="M.033 8.81 4.609.395a.265.265 0 0 1 .417-.063l.082.08a.265.265 0 0 1 .047.318L.562 9.064a.265.265 0 0 1-.231.137H.265a.265.265 0 0 1-.232-.391z"
            fill="#e5e5e5"
          />
          <path
            d="M19.147 1.14 5.004.134a.265.265 0 0 0-.22.435l9.605 11.305c.068.08.177.112.277.082l11.536-3.44a.265.265 0 0 0 .11-.443l-6.999-6.856a.265.265 0 0 0-.166-.075Z"
            fill="#e5e5e5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.683.654A.397.397 0 0 1 5.013 0L19.157 1.01a.397.397 0 0 1 .25.112l6.998 6.856a.397.397 0 0 1-.164.664l-11.536 3.441a.397.397 0 0 1-.416-.123zm.312-.389a.132.132 0 0 0-.11.218l9.605 11.305c.034.04.088.056.138.04l11.536-3.44a.132.132 0 0 0 .055-.222L19.22 1.31a.132.132 0 0 0-.083-.037Z"
            fill="#e5e5e5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgHousSides;
