import React,{ Component } from 'react'

export default class Default extends Component {
    state = { redirect: true };

    render() {
        if (this.state.redirect === true) {
            window.location.href = "https://blue7.it/index.php"
        }
        return (
            <div className="container my-5 py-5 text-center">
            </div>
        )
    }
}
